import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { isEmpty } from "lodash";
import PropTypes from 'prop-types';
import "./SelfSignup.scss";
import history from "../../history";
import { RouteName, SELF_SIGNUP_EMAIL_PATTERN } from "../../constants/Constants";
import PublicVariables from "../../constants/PublicVariables";
import { VerifyEmailForSelfSignup } from "../../actions/selfSignupAction";
import { FormikInputWithIcon } from "../UI/TextInput/FormikInputWithIcon";
import { SubmitButton } from "../UI/Button/Button";
import SelfSignup from "./SelfSignup";
import { Localize } from "./Localize";

const GetStarted = (props) => {
    const [emailParam, setEmailParam] = useState('');
    const [subscriptionType, setSubscriptionType] = useState('');
    const [localisedValues, setLocalisedValues] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const query = new URLSearchParams(history.location.search);

    useEffect(()=>{
        setEmailParam(query.get("email"));
        setSubscriptionType(query.get("type"));
    },[query.get("email")])

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(()=> {
        setIsSubmitting(props.selfSignup.verifyEmailLoading);
        if(props.selfSignup.verifyEmailData){
            history.push(RouteName.CONFIRMATION);
        }
    },[props.selfSignup.verifyEmailData, props.selfSignup.verifyEmailLoading])

    return (
        <SelfSignup showSignIn="true">
            <div className="signup-in-scroll custom-scroll-div">
                <div className="login-logo-subtext text-center" data-testid="heading">
                    <h2>{localisedValues['self_signup_title']}</h2>
                    <p>{localisedValues['self_signup_heading1']}</p>
                </div>
                    <Formik
                    initialValues={{
                        email: emailParam
                    }}
                    validationSchema={
                        Yup.object({
                            email: Yup.string()
                            .email(localisedValues['enter_valid_email'])
                            .required(localisedValues['enter_valid_email'])
                            .max(255, localisedValues['error_limit_exceeded'])
                            .matches(SELF_SIGNUP_EMAIL_PATTERN,localisedValues['use_business_email_error'])
                        })
                    }
                    onSubmit={(values, { _setSubmitting }) => {
                        setTimeout(() => {
                            console.log(values);
                            const updatedEmail = values?.email?.trim();
                            PublicVariables.trialemail.email = updatedEmail;
                            props.VerifyEmailForSelfSignup(updatedEmail, subscriptionType);
                        }, 400)
                    }}
                >
                {formik => (
                    <Form>
                        <FormikInputWithIcon
                            divClass="with-icon"
                            id="email"
                            type="email"
                            iconSpanClass="blue-icon opacity-1"
                            className="height-46 focus-font-bold"
                            placeholder={localisedValues["self_signup_sample_email"]}
                            touched={formik.touched.email}
                            error={formik.errors.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            iconClassName="form-group-icon-in icon-checked-fill"
                            isCheckValid={!formik.errors.email && !isEmpty(formik.values.email)}
                        />
                        <div className="button-inline text-center padding-top-10 d-flex justify-content-center">
                            <div className="button button-full-width">
                                <SubmitButton className="btn btn-primary font-weight-500 btn-with-icon" id="continue" data-testid="continueButton" name={localisedValues['continue']} isSubmitting={isSubmitting} disable={isSubmitting || !formik.isValid || formik.values.email === ''} showLoader={true} />
                            </div>
                        </div> 
                    </Form> 
                )}
                </Formik> 
                  
            </div>  
        </SelfSignup>
    )
}

const mapStateToPropsGetStarted = (state) => {
    return {
        language: state.language,
        selfSignup: state.selfSignup
    }
}

const mapDispatchToPropsGetStarted = (dispatch) =>{
    return {
        VerifyEmailForSelfSignup: (req, subscriptionType) => dispatch(VerifyEmailForSelfSignup(req, subscriptionType)),
    }
}

GetStarted.propTypes = {
    selfSignup: PropTypes.object,
    language: PropTypes.object,
    VerifyEmailForSelfSignup: PropTypes.func,
}

export default connect(mapStateToPropsGetStarted, mapDispatchToPropsGetStarted)(GetStarted);
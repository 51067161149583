import { Button } from "react-bootstrap";
import clsx from "clsx";
import PropTypes from 'prop-types';

export const SubmitButton = ({name, isSubmitting, showLoader, disable, className, ...otherProps}) => {

    const loader = <span className="btn-loader-icon"><span className="for-icon icon-loader spin"></span></span>;

    const styles = clsx(
                        "btn-with-icon login-btn", 
                        className ? className : ''
                    );

    return (
        <button type="submit" className={styles} disabled={disable || isSubmitting} {...otherProps}>
            {showLoader && isSubmitting && loader} {name ?? 'Submit'}
        </button>
    );
}

SubmitButton.propTypes = {
  name: PropTypes.string,
  isSubmitting: PropTypes.bool,
  showLoader: PropTypes.bool,
  disable: PropTypes.bool,
  className: PropTypes.string,
};


export const PrimaryButtonWithIcon = ({name, iconClass, onClick, ...otherProps}) => {
    const iconStyles = clsx(
        "btn-icon", 
        iconClass
    );

    return (
        <Button className="btn-with-icon" onClick={onClick} {...otherProps}>
            <span className={iconStyles}></span>
            <span className="btn-text">{name}</span>
        </Button>
    );
}

PrimaryButtonWithIcon.propTypes = {
  name: PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
};

export const SecondaryDropdownButton = ({id, name, className, ariaExpanded}) => {
    const styles = clsx(
        "btn btn-secondary dropdown-toggle triangle-none",
        className ? className : ''
    );

    return (
        <button className={styles} type="button" id={id} data-bs-toggle="dropdown" aria-expanded={ariaExpanded || "false"}>
            <span className="dropdown-toggle-text">{name}</span>
            <span className="icon-down-arrow"></span>
        </button>
    );
}

SecondaryDropdownButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  ariaExpanded: PropTypes.bool,
};

export const ButtonWithModel = ({name, btnClass, iconClass, onClick}) => {
    return (
        <button className={btnClass} data-bs-toggle="modal" onClick={onClick}>
            <span className={iconClass}></span>
            <span className="btn-text">{name}</span>
        </button>
    );
}

ButtonWithModel.propTypes = {
  name: PropTypes.string,
  btnClass: PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
};

export const ButtonWithLoader = ({name, showLoader, disable, className, ...otherProps}) => {

    const loader = (
      <span className="btn-loader-icon">
        <span className="for-icon icon-loader spin"></span>
      </span>
    );

    return (
        <button type={otherProps.type ? otherProps.type : "button"} className={className} disabled={disable} {...otherProps}>
            {showLoader && loader} {name ?? 'Submit'}
        </button>
    );
}

ButtonWithLoader.propTypes = {
  name: PropTypes.string,
  showLoader: PropTypes.bool,
  disable: PropTypes.bool,
  className: PropTypes.string,
};

export const RadioButton = ({
  value,
  label,
  showTooltip,
  tooltipValue,
  ...otherProps
}) => {
  return (
    <div className="radio-btn-box">
      <label>
        <input type="radio" name="usradiobtn" value={value} {...otherProps} />
        <span className="for-design"></span>
        {label}
      </label>
      {showTooltip && (
        <div className="custom-tooltip right-tooltip">
          <div className="custom-tooltip-icon">
            <span className="icon-info"></span>
          </div>
          <div className="custom-tooltip-content">
            <div className="custom-tooltip-in">
              <p>{tooltipValue}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

RadioButton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  showTooltip: PropTypes.bool,
  tooltipValue: PropTypes.string,
};
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { EMAIL_PATTERN_REGEX, LOCAL_STORAGE_KEYS, RouteName } from "../../constants/Constants";
import { SubmitButton } from "../UI/Button/Button";
import { Localize } from "./Localize";
import SelfSignup from "./SelfSignup";
import { InviteUserForSelfSignup } from "../../actions/selfSignupAction";
import history from "../../history";
import { getDomainURL } from "../../shared/utility";
import storage from "../../services/storage";
import PublicVariables from "../../constants/PublicVariables";

const Invites = (props) => {
    const [localisedValues, setLocalisedValues] = useState({});
    const [formValues, setFormValues] = useState([{email : "", error: ""}]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isError, setIsError] = useState(true);
   
    const validateEmail = (changedValue) => {
		let error = "";
        if(changedValue !== ""){
            if (!EMAIL_PATTERN_REGEX.test(changedValue) || changedValue.trim().length > 255) {
                error = localisedValues['enter_valid_email']
            }
        }
   		return (error);
	}

    const checkDuplicate = (newFormValues) => {
        newFormValues.forEach(function (value, _key) {
            let count =0;
            if(PublicVariables.trialemail && (value.email === PublicVariables.trialemail.email)){
                value.isDuplicate= true;
            }else{
                newFormValues.forEach(function (value1, _key1) {
                    if(count<2){
                        if(value.email && value.email !== '' && (value.email === value1.email)){
                            count++;
                            value.isDuplicate = count > 1
                        }else{
                            value.isDuplicate = false;
                        } 
                    }                                               
                });
            }
        }); 
        return newFormValues;
    }

    const commonLogic = (newFormValues) => {
        newFormValues = checkDuplicate(newFormValues);
        if(newFormValues.length === newFormValues.filter(item => (item.error === "" && item.isDuplicate === false)).length){ // for send invite button disable
            setIsError(false);
        }else{
            setIsError(true);
        }
        setFormValues(newFormValues);
    }
    const handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        const error = validateEmail(e.target.value);
        newFormValues[i]["error"] = error;
        commonLogic(newFormValues);
    }
    
    const addFormFields = () => {
        setFormValues([...formValues, {email : "", error: ""}])
    }
    
    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        commonLogic(newFormValues)
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let UEMAILLIST = [];
        formValues.forEach(function (value, key) {
            UEMAILLIST.push({
                obj: key+1, 
                UEMAIL:value.email, 
                RCODE:'ADM',
                UCID:"",
                isDuplicate: false
            })
        })
        props.InviteUserForSelfSignup({DOMAINURL: getDomainURL() ?? "" ,UEMAILLIST:UEMAILLIST});
    }

    const gotoSessions = () => {
        storage.set(LOCAL_STORAGE_KEYS.USER_INVITED, "True");
        history.replace(RouteName.SESSIONS);
    }

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(() => {
        setIsSubmitting(props.selfSignup.inviteUserLoading);
        if(props.selfSignup.inviteUserData){
            gotoSessions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selfSignup.inviteUserLoading, props.selfSignup.inviteUserData]);

    return (
        <SelfSignup>
            <div className="signup-in-scroll custom-scroll-div">
                <div className="signup-stepno">
                    {localisedValues['step_3_of_3']}
                </div>
                <div className="login-logo-subtext text-center">
                    <h2>{localisedValues['send_invitations_title']}</h2>
                    <p>{localisedValues['send_invitations_heading']}</p>
                </div>
                <form  onSubmit={handleSubmit}>
                    <div className="signup-send-invitation">
                        <div className="send-invitation-hdr clearfix">
                            <div className="send-invitation-email">
                                {localisedValues['new_email_address']}
                            </div>
                        </div>
                        <div className="signup-invitation-email-list">
                            <div className="send-invitation-row-out">
                            {formValues.map((element, index) => (
                                <div className="send-invitation-row " key={index}>
                                    <div className="invitation-email-box">
                                        <div className="form-group">
                                            <div className="form-group-in">
                                                <input 
                                                    className="form-control form-control-grey height-46 focus-font-bold" 
                                                    placeholder={localisedValues['email_id']}
                                                    type="email" 
                                                    name="email" 
                                                    value={element.email || ""} 
                                                    onChange={e => handleChange(index, e)}
                                                />
                                            </div>	
                                            {element.error && <div className="validation-error">
                                                {element.error}
                                            </div>}	
                                            {(element.error === "" && element.isDuplicate) && <div className="validation-error">
                                                {localisedValues['duplicate_email_address']}
                                            </div>}				
                                        </div>
                                    </div>
                                    <div className="email-remove-btn">
                                        <button className="btn" onClick={() => removeFormFields(index)}>
                                            <span className="btn-icon icon-close-thin"></span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                       
                        <div className="add-item-btn">
                            {(formValues.length <= 9) && 
                                <button type="button" className="btn" onClick={() => addFormFields()}>
                                    <span className="btn-icon icon-plus-round"></span>
                                    <span className="btn-text">{localisedValues['add_another_user']}</span>
                                </button>
                            }
                            {/* change it with info icon */}
                            {(formValues.length > 9) && 
                                <div className="info-msg">
                                    <span className="for-icon icon-information"></span>
                                    {localisedValues['add_members_msg']}
                                </div>
                            }	
                        </div>
                       
                    </div>
                    <div className="button-inline text-center padding-top-15 d-flex justify-content-center">
                        <div className="button button-full-width">
                            <SubmitButton className="btn btn-primary font-weight-500" id="Send_Invitation" name={localisedValues['send_invitations_title']} isSubmitting={isSubmitting} disable={isSubmitting || isError} showLoader={true} />
                        </div>
                    </div>
                </form>
                <div className="skip-grey-btn padding-top-10 text-center">
                    <button type="button" target="_blank" className="btn" onClick={gotoSessions}>{localisedValues['skip_for_now']}</button>
                </div>
            </div> 
        </SelfSignup>
    )
}

const mapStateToPropsInvites = (state) => {
    return {
        language: state.language,
        selfSignup: state.selfSignup
    }
}

const mapDispatchToPropsInvites = (dispatch) =>{
    return {
        InviteUserForSelfSignup: (req) => dispatch(InviteUserForSelfSignup(req)),
    }
}

Invites.propTypes = {
    selfSignup: PropTypes.object,
    language: PropTypes.object,
    InviteUserForSelfSignup: PropTypes.func,
}

export default connect(mapStateToPropsInvites, mapDispatchToPropsInvites)(Invites);
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import PropTypes from 'prop-types';
import noFilesImage from "../../../../assets/images/no-files.svg";
import {
  CheckboxColumnFormatter,
  ExpandRowForFilesTab,
} from "./ColumnFormatter/ColumnFormatter";
import {
  convertUtcToLocalInStr,
  insert,
  removeFromArray,
} from "../../../../shared/utility";
import { Localize } from "../Localize";
import { PopupModal } from "../../../UI/Modal/Modal";
import CustomToast from "../../../UI/customToast";
import { CheckboxHeaderFormatter } from "./ColumnFormatter/HeaderFormatter";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../UI/DataTable/Pagination/TableSortingHelpers";
import BootstrapDataTable from "../../../UI/DataTable/BootstrapDataTable";

const FilesTableView = ({
  filesData,
  setFilesData,
  selectedFiles,
  setSelectedFiles,
  columnsToShow,
}) => {
  const { language } = useSelector((state) => state);

  const [isShowViewModal, setIsShowViewModal] = useState(false);
  const [viewFileData, setViewFileData] = useState({});
  const [localisedValues, setLocalisedValues] = useState({});

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  const selectChangeHandler = (filePath, isSelected) => {
    const updatedData = [...filesData].map((item) => {
      if (item.filePath === filePath) {
        item.ISSELECTED = isSelected;

        const index = selectedFiles.findIndex(
          (file) => file.filePath === filePath
        );
        if (index >= 0) {
          setSelectedFiles(removeFromArray(selectedFiles, index));
        } else {
          setSelectedFiles(insert(selectedFiles, selectedFiles.length, item));
        }
      }
      return item;
    });
    setFilesData(updatedData);
  };

  const selectAllChangeHandler = (e) => {
    const checked = e.target.checked;
    const updatedRemovingAllFiles = [...selectedFiles];

    const updatedData = [...filesData].map((item) => {
      item.ISSELECTED = checked;

      const index = updatedRemovingAllFiles.findIndex(
        (data) => data.filePath === item.filePath
      );
      if (index >= 0 && !checked) updatedRemovingAllFiles.splice(index, 1);
      else if (index < 0 && checked) updatedRemovingAllFiles.push(item);

      return item;
    });
    setFilesData(updatedData);
    setSelectedFiles(updatedRemovingAllFiles);
  };

  const showViewFileModal = (row) => {
    axios
      .get(row.fileUrl)
      .then((response) => {
        const updatedFileViewData = response.data
          ?.replace(/\n/g, "<br>")
          .split("<br>");
        const viewData = {
          fileName: row.fileName,
          fileUrl: row.fileUrl,
          fileData: updatedFileViewData,
        };
        setIsShowViewModal(true);
        setViewFileData(viewData);
      })
      .catch((error) => {
        console.error("Error fetching file data", error);
      });
  };

  const closeViewFileModal = () => {
    setIsShowViewModal(false);
  };

  const downloadFileHandler = (url) => {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    setIsShowViewModal(false);
    CustomToast.success(localisedValues['successfully_downloaded']);
  };

  const columns = [
    {
      dataField: "filePath",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "RowSelector",
      text: "",
      formatter: CheckboxColumnFormatter,
      formatExtraData: {
        selectChangeHandler: selectChangeHandler,
        selectAllChangeHandler: selectAllChangeHandler,
      },
      headerAttrs: {
        "data-test":
          filesData.length > 0 && filesData.every((file) => file.ISSELECTED),
      },
      headerFormatter: CheckboxHeaderFormatter,
      headerClasses: "tbl-th-select",
    },
    {
      dataField: "fileName",
      text: localisedValues["file_name"],
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "tbl-th-filename",
      headerSortingClasses,
      formatter: (_cell, row) => (
        <div className="file-name-btn">
          <button
            className="btn"
            title={row.fileName}
            onClick={() => showViewFileModal(row)}
          >
            {row.fileName}
          </button>
        </div>
      ),
    },
    {
      dataField: "createDateTime",
      text: localisedValues["created_on"],
      hidden: columnsToShow < 3,
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "tbl-th-createdon",
      headerSortingClasses,
      formatter: (_cell, row) => (
        <div className="file-created-on">
          {convertUtcToLocalInStr(row.createDateTime)}
        </div>
      ),
    },
    {
      dataField: "size",
      text: localisedValues["file_size"],
      hidden: columnsToShow < 4,
      sort: true,
      sortCaret: sortCaret,
      headerClasses: "tbl-th-filesize",
      headerSortingClasses,
      formatter: (_cell, row) => <div className="file-size">{row.size} B</div>,
    },
    {
      dataField: "filepath",
      text: "",
      sort: true,
      headerClasses: "tbl-th-action",
      formatter: (_cell, row) => (
          <div className="button-inline file-action-button-row">
            <div className="button">
              <button
                className="btn file-action-btn"
                onClick={() => showViewFileModal(row)}
                data-testid="ViewFileBtn"
              >
                <span className="for-icon icon-view-file"></span>
                <span className="for-text">{localisedValues["view_key"]}</span>
              </button>
            </div>
            <div className="button">
              <button
                className="btn file-action-btn"
                onClick={() => downloadFileHandler(row.fileUrl)}
              >
                <span className="for-icon icon-download"></span>
                <span className="for-text">
                  {localisedValues["download_key"]}
                </span>
              </button>
            </div>
          </div>
      ),
    },
  ];

  //View file modal helpers
  const fileViewModalHeaderContent = (
    <>
      <h5 className="modal-title">{viewFileData.fileName}</h5>
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={closeViewFileModal}
      >
        <span className="icon-close"></span>
      </button>
    </>
  );

  const fileViewModalBodyContent = (
    <div className="previewtxt">
      {viewFileData.fileData &&
        viewFileData.fileData.length > 0 &&
        viewFileData.fileData.map((item, index) => {
          return <p key={index}>{item}</p>;
        })}
    </div>
  );

  const fileViewModalFooterContent = (
    <>
      <div className="button">
        <button
          type="button"
          className="btn btn-outline-secondary font-weight-500"
          onClick={closeViewFileModal}
        >
          {localisedValues["close"]}
        </button>
      </div>
      <div className="button">
        <button
          type="button"
          className="btn btn-primary font-weight-500"
          onClick={() => downloadFileHandler(viewFileData.fileUrl)}
        >
          {localisedValues["download_key"]}
        </button>
      </div>
    </>
  );

  return filesData.length > 0 ? (
    <div className="files-table custom-scroll-div">
      <BootstrapDataTable
        rowClasses={(row) => selectedFiles.some((file) => file.filePath === row.filePath) ? 'selected-row' : ''}
        columns={columns}
        expandRow={ExpandRowForFilesTab(columnsToShow, localisedValues)}
        tableData={filesData}
        tableKey="filePath"
        noDataMessage={"No data found"}
        hidePagination={true}
      />
      {isShowViewModal && (
        <PopupModal
          closeModal={closeViewFileModal}
          modalClass="chat-file-preview alert-cms-modal"
          dialogClass="modal-dialog-centered modal-dialog-scrollable"
          closeButton={false}
          headerContent={fileViewModalHeaderContent}
          bodyContent={fileViewModalBodyContent}
          bodyClass="modal-chatlist custom-scroll-div"
          footerContent={fileViewModalFooterContent}
          backdrop="static"
        />
      )}
    </div>
  ) : (
    <div className="no-session-data custom-scroll-div">
      <div className="no-session-data-in">
        <div className="no-session-data-box">
          <div className="image">
            <img src={noFilesImage} alt="No file" />
          </div>
          <p>{localisedValues["files_available_text"]}</p>
        </div>
      </div>
    </div>
  );
};

FilesTableView.propTypes = {
  filesData: PropTypes.array,
  setFilesData: PropTypes.func,
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.func,
  columnsToShow: PropTypes.number,
}

export default FilesTableView;

import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { get, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import "./Sendcode.scss";
import PublicVariables from "../../constants/PublicVariables";
import {
  createSession,
  createSessionReset,
  getAllCountries,
  getOwnCPList,
} from "../../actions/sessionListAction";
import { getSessionDetails } from "../../actions/sessionDetailAction";
import {
  CREATE_SESSION_FROM,
  EMAIL_PATTERN_REGEX,
  INVITE_USERS_TYPE,
  JOURNEYDEV_URL,
  LOCAL_STORAGE_KEYS,
  LOCAL_STORAGE_KEYS_ADMIN,
  RouteName,
  SEND_LINK_TYPES,
  UK_COUNTRY_CODE_NEW,
  UK_COUNTRY_CODE_OLD,
  WHATSAPP_INVITATION_PREVIEW_TEXT,
} from "../../constants/Constants";
import storage from "../../services/storage";
import history from "../../history";
import { fetchInvitationURL, filterParams, getMeetingLinkUrlForKey, createMeetingUrl, handleErrorMsgToastVisiblity, isUploadOnlySession, parseURLParams } from "../../shared/utility";
import { Localize } from "./Localize";
import SendEmail from "./SendEmail";
import SendMsg from "./SendMsg";
import SendOther from "./SendOther";
import SendSmartglass from "./SendSmartglass";
import { RadioButton } from "../UI/Button/Button";

const SendCode = () => {
  const location = useLocation();
  const { isFromSessionList = false, buddyData = null, isFromChangeSchedule = false } = location.state || {};

  const dispatch = useDispatch();
  const { getCountriesData, getOwnCPListData, createSessionsData, getOwnCPListLoading, createSessionsLoading, createSessionsError } =
    useSelector((state) => state.sessionList);
  const { getSessionDetailsData, getSessionDetailsLoading } = useSelector((state) => state.sessionDetail);
  const { language } = useSelector((state) => state);

  const dataForSession = useMemo(() => { return parseURLParams(storage.get(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION) || '') }, []);

  //SMS, WhatsApp and email input refs
  const smsInputValueRef = useRef("");
  const whatsAppInputValueRef = useRef("");
  const emailRef = useRef([]);
  //SMS and WhatsApp country refs
  const smsCountryRef = useRef("");
  const selectedSMSCountry = storage.get("SMSCountrySelected");
  const whatsAppCountryRef = useRef("");
  const selectedWhatsAppCountry = storage.get("WhatsAppCountrySelected");
  //Once invite user API called from any tab
  const isInviteAPICalledRef = useRef(false);

  const [sessionData, setSessionData] = useState(getSessionDetailsData);
  const [selectedTab, setSelectedTab] = useState("1");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [inviteUser, setInviteUser] = useState(INVITE_USERS_TYPE.GUEST);
  const [whatsAppTemplate, setWhatsAppTemplate] = useState("");
  const [smsTemplate, setSmsTemplate] = useState("");
  const [agentUrl, setAgentUrl] = useState("");
  const [inviteUrl, setInviteUrl] = useState("");
  const [localisedValues, setLocalisedValues] = useState({});
  const [defaultCountry, setDefaultCountry] = useState({});
  const [isApiCalled, setIsApiCalled] = useState(false);

  const ldFlags = useFlags();

  const isUploadOnlySessionValue = useMemo(() => {
    return isUploadOnlySession(
      sessionData?.features?.LIVEVIDEO,
      sessionData?.features?.ENABLESCREENSHARE,
      sessionData?.features?.UPLOADFILE
    );
  }, [sessionData]);

  const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
  const configurationdata = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION) }, []);
  const configData = storage.get(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA);
  const inviteURL = fetchInvitationURL();

  ////Show/hide backdrop of Send Invite modal based on URL parameter & LD Flag
  const showBackdrop = useMemo(() => {
    return dataForSession?.page === '1';
  }, [ldFlags]);

  useEffect(() => {
    if (getCountriesData) {
      const countryDetails =
        getCountriesData?.length > 0 &&
        getCountriesData.filter((item) => item.isDefault === true);

      setDefaultCountry(countryDetails[0]);
      setNordicCountrySMSMessage(selectedSMSCountry ?? countryDetails[0]);
    }
  }, [getCountriesData]);

  const updateNordicCountryMessage = (SMSCountry) => {
    if (getCountriesData && SMSCountry) {
      const updatedCountryData = getCountriesData.filter(
        (item) => item.countryCode.toLowerCase() === SMSCountry.iso2
      );
      setNordicCountrySMSMessage(updatedCountryData[0]);
    }
  };

  useEffect(() => {
    setNordicCountrySMSMessage(selectedSMSCountry ?? defaultCountry);
  }, [sessionData?.smsTemplate]);

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  useEffect(() => {
    if(isEmpty(getCountriesData)){
      dispatch(getAllCountries());
    }
  }, [])

  useEffect(() => {
    if (createSessionsData?.sessionId || (isFromChangeSchedule && sessionData)) {
      storage.set(LOCAL_STORAGE_KEYS.IS_CREATED_SESSION, '1');
      storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION);
      dispatch(getSessionDetails({ sessionId: createSessionsData.sessionId ?? sessionData?.session?.sessionId }));
    }
    return () => dispatch(createSessionReset());
  }, [createSessionsData, isFromChangeSchedule]);

  useEffect(() => {
    setSessionData(getSessionDetailsData);
  }, [getSessionDetailsData]);

  useEffect(() => {
    smsCountryRef.current = ""
    if (!getSessionDetailsLoading && !sessionData && !createSessionsData && isEmpty(dataForSession)) {
      history.replace(RouteName.SESSIONS);
    }

    return () => {
      if (storage.get(LOCAL_STORAGE_KEYS.IS_CREATED_SESSION) === '1' && !storage.has(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION)) {
        dispatch(createSessionReset())
        storage.set(LOCAL_STORAGE_KEYS.IS_CREATED_SESSION, '0')
        if (window.location.pathname !== RouteName.LOGOUT) {
          history.replace(RouteName.SESSIONS);
        }
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (buddyData && Object.keys(buddyData).length > 0) {
        history.replace({
          pathname: RouteName.CREATE_SESSION,
          state: { isFromSendCode: true },
        });
        dispatch(createSessionReset())
        storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION)
      } else {
        history.replace(RouteName.SESSIONS);
        dispatch(createSessionReset())
        storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION)
      }
    });
  }, [history]);

  useEffect(() => {
    if (!createSessionsData && isEmpty(dataForSession) && !isFromSessionList && !isFromChangeSchedule) return;

    if (getOwnCPListLoading || createSessionsLoading) return

    if (createSessionsError) {
      // Resetting create Sessions Store data if any error accured during Creating Session 
      dispatch(createSessionReset())
      handleErrorMsgToastVisiblity(createSessionsError.message)
    }

    if (isEmpty(getOwnCPListData)) {
      dispatch(getOwnCPList())
    }

    let itFindInviteType = false

    if (!isEmpty(dataForSession) && !isEmpty(getOwnCPListData)) {

      let selectedColabrationProfile = null
      const cpListData = get(getOwnCPListData, 'items', [])
      if (dataForSession.callexperience) {
        const callExp = cpListData.find(obj => obj.name === dataForSession.callexperience);
        selectedColabrationProfile = callExp ?? cpListData[0];
      } else {
        selectedColabrationProfile = cpListData[0]
      }

      let payload = {
        sessionType: 1,
        from: CREATE_SESSION_FROM.REGULAR,
        reference: dataForSession.ref ?? '',
        collaborationProfileId: selectedColabrationProfile.collaborationProfileId,
      }
      const extraParams = filterParams(storage.get(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION))

      if (extraParams.length > 0 && extraParams[0] !== "") {
        let extraParamsStr = ""
        extraParamsStr = '&' + extraParams.join("&")
        if (extraParamsStr) {
          payload.queryString = extraParamsStr
        }
      }

      if (isEmpty(createSessionsData) && !isApiCalled) {
        //// if creating session on parameterised URL, than set from value to 7 as per decision on Sasktel issue
        payload.from = CREATE_SESSION_FROM.PARAMETERISED_URL;

        dispatch(createSession(payload))
        setIsApiCalled(true)
        return
      }
      else {
        if (!isEmpty(dataForSession.sms) && sessionData?.features?.SMSINVITE === '1') {
          smsInputValueRef.current = dataForSession.sms
          handleOnSelectTab(SEND_LINK_TYPES.SMS.value)
          itFindInviteType = true
        } else if (!isEmpty(dataForSession.whatsapp) && sessionData?.features?.WHATSAPPINVITE === '1') {
          whatsAppInputValueRef.current = dataForSession.whatsapp
          handleOnSelectTab(SEND_LINK_TYPES.WhatsApp.value)
          itFindInviteType = true
        } else if (!isEmpty(dataForSession.email) && sessionData?.features?.EMAILINVITE === '1') {

          const emaiRregex = new RegExp(EMAIL_PATTERN_REGEX);
          const isValid = emaiRregex.test(dataForSession.email)

          if (isValid) {
            emailRef.current = [dataForSession.email]

          }
          handleOnSelectTab(SEND_LINK_TYPES.Email.value)
          itFindInviteType = true
        }
      }
    }

    const selectedCPData =
      getOwnCPListData?.items?.length > 0 &&
      sessionData &&
      getOwnCPListData.items.filter(
        (item) =>
          sessionData.session?.collaborationProfileId ===
          item.collaborationProfileId
      );

    if (!itFindInviteType) {
      if (selectedCPData?.length > 0) {
        if((selectedCPData[0]?.configurations?.DEFAULTINVITETYPE === "2" && PublicVariables.ldFlags?.inviteViaWhatsApp && sessionData?.features?.WHATSAPPINVITE === "1") || selectedCPData[0]?.configurations?.DEFAULTINVITETYPE !== "2"){
          setSelectedTab(selectedCPData[0]?.configurations?.DEFAULTINVITETYPE);
        }
        else{
          setSelectedTab(SEND_LINK_TYPES.SMS.value);
        }
      } else {
        setSelectedTab(SEND_LINK_TYPES.Other.value);
      }
    }
  }, [sessionData, getOwnCPListData, createSessionsData]);

  useEffect(() => {
    const updatedWhatsAppText = WHATSAPP_INVITATION_PREVIEW_TEXT.replace(
      "<company_name>",
      userData?.domainName
    );

    //Agent url
    let agent_url = createMeetingUrl(inviteURL, sessionData, INVITE_USERS_TYPE.AGENT);

    // add closetab, platform and redirect_url params if available
    if (!isEmpty(dataForSession)) {
      if (dataForSession.closetab || dataForSession.closetab == '0' || dataForSession.closetab == 'false') {
        agent_url = agent_url + "&closetab=" + dataForSession.closetab
      }
      if (dataForSession.platform || dataForSession.platform == '0' || dataForSession.platform == 'false') {
        agent_url = agent_url + "&platform=" + dataForSession.platform
      }
      if (dataForSession.redirect_url || dataForSession.redirect_url == '0' || dataForSession.redirect_url == 'false') {
        agent_url = agent_url + "&redirect_url=" + dataForSession.redirect_url
      }
    }
    setAgentUrl(agent_url);

    let sendUrl = "";
    if (inviteUser === INVITE_USERS_TYPE.SILENT_OBSERVER) {
      if (sessionData?.features?.SHOWMEOBSERVER === "1") {
        //Silent user url
        sendUrl = createMeetingUrl(inviteURL, sessionData, INVITE_USERS_TYPE.SILENT_OBSERVER);
        setInviteUrl(sendUrl);
        setWhatsAppTemplate(updatedWhatsAppText + " " + sendUrl);
      }
    } else {
      //Guest user url
      sendUrl = createMeetingUrl(inviteURL, sessionData);
      setInviteUrl(sendUrl);
      setWhatsAppTemplate(updatedWhatsAppText + " " + sendUrl);
    }
  }, [sessionData, inviteUser, userData, inviteURL]);

  useEffect(() => {
    const dropdown = document.getElementById("moreDropdown");
    if (dropdown && !isDropdownVisible) {
      if (dropdown.classList.contains("show")) {
        document.getElementById("moreDropdown").classList.remove("show");
      }
    }
  }, [isDropdownVisible]);

  const isSMSTemplateAvailable = () => {
    if (sessionData?.smsTemplate) {
      setSmsTemplate(sessionData?.smsTemplate);
    } else {
      setSmsTemplate(
        "<company_name> has sent you a link for live video conference.".replace(
          "<company_name>",
          userData.domainName
        )
      );
    }
  };

  //Update SMS message when nordic country
  const setNordicCountrySMSMessage = (countryDetails) => {
    let lastSelectedCountry;
    if (countryDetails && !countryDetails.isNordicCountry && getCountriesData) {
      lastSelectedCountry = getCountriesData.find(
        (value) => value.countryCode.toLowerCase() === countryDetails?.iso2
      );
    } else {
      lastSelectedCountry = countryDetails;
    }

    if (
      !configurationdata?.twilioWhitelisted &&
      lastSelectedCountry?.isNordicCountry
    ) {
      const customizedMessage = `Use this code to get started: ${JOURNEYDEV_URL}${getMeetingLinkUrlForKey(sessionData?.features?.USEDAILY)}${sessionData?.session?.sessionId}`;
      setSmsTemplate(customizedMessage);
    } else {
      isSMSTemplateAvailable();
    }
  };

  const handleOnSelectTab = (type) => {
    setSelectedTab(type);
    setIsDropdownVisible(false);
  };

  const onMoreButtonClick = () => {
    setIsDropdownVisible(true);
  };

  const oncloseInviteHandler = () => {
    dispatch(createSessionReset());
    history.replace(RouteName.SESSIONS);
    storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION)
  };

  /*Update sendCode tab nav class when 
    sms, whatsApp and smartGlass invitation are off */
  const sendCodeNavClass = () => {
    const smsInvitationOff = sessionData?.features?.SMSINVITE === "0"; // Sms invitaion-off
    const whatsAppInvitationOff = sessionData?.features?.WHATSAPPINVITE === "0"; // Whatsapp invitaion-off
    const smartglassInvitationOff =
      sessionData?.features?.SHOWMESMARTGLASS === "0"; // Smartglass invitaion-off

    const sendCodeNavUpdatedClass = clsx(
      "sendcode-tab-nav",
      smsInvitationOff && whatsAppInvitationOff && smartglassInvitationOff
        ? "all-invitation-off"
        : ""
    );

    return sendCodeNavUpdatedClass;
  };

  const getSessionVisibility = () => {
    const configurationdata = storage.get(
      LOCAL_STORAGE_KEYS?.DOMAIN_CONFIGURATION
    );
    return configurationdata?.configurations?.sessionvisibilitypage;
  };

  //Is login user is owner of session
  const isSessionOwner = () => {
    return !(
      getSessionVisibility() === true &&
      sessionData?.session?.ownerUserId !== storage.get(LOCAL_STORAGE_KEYS.UCID)
    );
  };

  const convertCode = (code) => {
    if (code === UK_COUNTRY_CODE_NEW) return UK_COUNTRY_CODE_OLD;

    return code;
  }

  const smsDefaultCountryCode = () => {
    const countryCode = convertCode(smsCountryRef.current?.iso2 ?? selectedSMSCountry?.iso2 ?? (defaultCountry?.countryCode?.toLowerCase() ?? ''));
    return countryCode;
  }

  const whatsappDefaultCountryCode = () => {
    const countryCode = convertCode(whatsAppCountryRef.current?.iso2 ?? selectedWhatsAppCountry?.iso2 ?? (defaultCountry?.countryCode?.toLowerCase() ?? ''));
    return countryCode;
  }

  return (
    <>
      {sessionData && <div className={clsx("sendcode", showBackdrop && "sasktel-sendcode", "custom-scroll-div")}>
        {showBackdrop && <div className="sendcode-overlay"></div>}
        <div className="sendcode-box">
          <div className="sendcode-head">
            <div className="sendcode-head-text">
              {dataForSession.page !== '1' && <div className="sendcode-head-top">
                {localisedValues["step_2_of_2"]}
                <span className="next-icon icon-right-step"></span>
              </div>}
              <div className="sendcode-head-mid">
                {buddyData?.USER?.name
                  ? localisedValues["share_invite_with"]?.replace(
                    "{0}",
                    buddyData.USER.name
                  )
                  : localisedValues["share_invite"]}
              </div>
            </div>
            {dataForSession.page !== '1' && <button
              type="button"
              className="btn-close"
              data-testid='iconCloseBtn'
              onClick={oncloseInviteHandler}
            >
              <span className="icon-close"></span>
            </button>}
          </div>
          <div className="sendcode-body">
            <div className={sendCodeNavClass()}>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {sessionData?.features?.SMSINVITE === "1" && (
                  <li className="nav-item" role="presentation">
                    <button
                      className={clsx(
                        "nav-link invite-nav-link",
                        selectedTab === SEND_LINK_TYPES.SMS.value && "active"
                      )}
                      id="invite-sms-tab"
                      data-testid='inviteSmsTab'
                      data-bs-toggle="tab"
                      data-bs-target="#invite-sms"
                      type="button"
                      role="tab"
                      aria-selected="true"
                      onClick={() => handleOnSelectTab(SEND_LINK_TYPES.SMS.value)}
                    >
                      <span className="btn-icon icon-chat-sms"></span>
                      <span className="btn-text">
                        {localisedValues["sms_label"]}
                      </span>
                    </button>
                  </li>
                )}
                {PublicVariables.ldFlags?.inviteViaWhatsApp &&
                  sessionData?.features?.WHATSAPPINVITE === "1" && (
                    <li className="nav-item" role="presentation">
                      <button
                        className={clsx(
                          "nav-link invite-nav-link",
                          selectedTab === SEND_LINK_TYPES.WhatsApp.value &&
                          "active"
                        )}
                        id="invite-whatsapp-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#invite-whatsapp"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        onClick={() =>
                          handleOnSelectTab(SEND_LINK_TYPES.WhatsApp.value)
                        }
                      >
                        <span className="btn-icon icon-whatsapp"></span>
                        <span className="btn-text">
                          {localisedValues["whatsapp_label"]}
                        </span>
                      </button>
                    </li>
                  )}
                {PublicVariables?.ldFlags?.inviteViaSmartglass &&
                  sessionData?.features?.SHOWMESMARTGLASS === "1" &&
                  !isUploadOnlySessionValue && (
                    <li className="nav-item" role="presentation">
                      <button
                        className={clsx(
                          "nav-link invite-nav-link",
                          selectedTab === SEND_LINK_TYPES.Smartglass.value && "active"
                        )}
                        id="invite-smartglass-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#invite-smartglass"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        onClick={() =>
                          handleOnSelectTab(SEND_LINK_TYPES.Smartglass.value)
                        }
                      >
                        <span className="btn-icon icon-smartglass"></span>
                        <span className="btn-text">
                          {SEND_LINK_TYPES.Smartglass.name}
                        </span>
                      </button>
                    </li>
                  )}
                <li
                  className="nav-item dropdown sendcode-dropdown"
                  role="presentation"
                >
                  <button
                    className={clsx(
                      "btn invite-nav-link dropdown-toggle triangle-none",
                      (selectedTab === SEND_LINK_TYPES.Email.value ||
                        selectedTab === SEND_LINK_TYPES.Other.value) &&
                      "active"
                    )}
                    id="sendcodetabnav"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={onMoreButtonClick}
                  >
                    <span className="btn-icon icon-more"></span>
                    <span className="btn-text">{localisedValues["more"]}</span>
                  </button>
                  <ul
                    className="dropdown-menu right sendcode-dropdown-menu"
                    aria-labelledby="dropdown"
                    id="moreDropdown"
                  >
                    {sessionData?.features?.EMAILINVITE === "1" && (
                      <li className="nav-item" role="presentation">
                        <button
                          className={clsx(
                            "nav-link",
                            selectedTab === SEND_LINK_TYPES.Email.value &&
                            "active"
                          )}
                          id="invite-email-tab"
                          data-bs-toggle="tab"
                          data-testid="invite-email"
                          data-bs-target="#invite-email"
                          type="button"
                          role="tab"
                          aria-controls="other1"
                          aria-selected="false"
                          onClick={() =>
                            handleOnSelectTab(SEND_LINK_TYPES.Email.value)
                          }
                        >
                          {localisedValues["invite_by_email"]}
                        </button>
                      </li>
                    )}
                    <li className="nav-item" role="presentation">
                      <button
                        className={clsx(
                          "nav-link",
                          selectedTab === SEND_LINK_TYPES.Other.value && "active"
                        )}
                        id="invite-other-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#invite-other"
                        type="button"
                        role="tab"
                        aria-controls="other2"
                        aria-selected="false"
                        onClick={() =>
                          handleOnSelectTab(SEND_LINK_TYPES.Other.value)
                        }
                      >
                        {localisedValues["other"]}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              {selectedTab === SEND_LINK_TYPES.SMS.value && (
                <SendMsg
                  id="invite-sms"
                  ariaLabelledby="invite-sms-tab"
                  type="SMS"
                  isDefaultInvite={selectedTab === SEND_LINK_TYPES.SMS.value}
                  sessionDetailData={sessionData}
                  message={smsTemplate}
                  sendUrl={inviteUrl}
                  agentUrl={agentUrl}
                  countryRef={smsCountryRef}
                  getCountriesData={getCountriesData}
                  defaultCountry={smsDefaultCountryCode()}
                  isUploadOnlySessionValue={isUploadOnlySessionValue}
                  isInviteAPICalledRef={isInviteAPICalledRef}
                  mobileNumberRef={smsInputValueRef}
                  updateNordicCountryMessage={updateNordicCountryMessage}
                  isSessionOwner={isSessionOwner()}
                  isForSaskTail={!isEmpty(dataForSession)}
                />
              )}
              {selectedTab === SEND_LINK_TYPES.WhatsApp.value && (
                <SendMsg
                  id="invite-whatsapp"
                  ariaLabelledby="invite-whatsapp-tab"
                  type="WhatsApp"
                  isDefaultInvite={selectedTab === SEND_LINK_TYPES.WhatsApp.value}
                  sessionDetailData={sessionData}
                  message={whatsAppTemplate}
                  sendUrl={inviteUrl}
                  agentUrl={agentUrl}
                  countryRef={whatsAppCountryRef}
                  defaultCountry={whatsappDefaultCountryCode()}
                  isUploadOnlySessionValue={isUploadOnlySessionValue}
                  mobileNumberRef={whatsAppInputValueRef}
                  isInviteAPICalledRef={isInviteAPICalledRef}
                  isSessionOwner={isSessionOwner()}
                  isForSaskTail={!isEmpty(dataForSession)}
                />
              )}
              {selectedTab === SEND_LINK_TYPES.Smartglass.value && (
                <SendSmartglass
                  id="invite-smartglass"
                  isDefaultInvite={selectedTab === SEND_LINK_TYPES.Smartglass.value}
                  sessionDetailData={sessionData}
                  sendUrl={inviteUrl}
                  agentUrl={agentUrl}
                  isInviteAPICalledRef={isInviteAPICalledRef}
                  isSessionOwner={isSessionOwner()}
                  isForSaskTail={!isEmpty(dataForSession)}
                />
              )}
              {selectedTab === SEND_LINK_TYPES.Email.value && (
                <SendEmail
                  id="invite-email"
                  isDefaultInvite={selectedTab === SEND_LINK_TYPES.Email.value}
                  sessionDetailData={sessionData}
                  sendUrl={inviteUrl}
                  agentUrl={agentUrl}
                  userData={userData}
                  configData={configData}
                  isUploadOnlySessionValue={isUploadOnlySessionValue}
                  emailRef={emailRef}
                  isInviteAPICalledRef={isInviteAPICalledRef}
                  isSessionOwner={isSessionOwner()}
                  isForSaskTail={!isEmpty(dataForSession)}
                />
              )}
              {selectedTab === SEND_LINK_TYPES.Other.value && (
                <SendOther
                  id="invite-other"
                  isDefaultInvite={selectedTab === SEND_LINK_TYPES.Other.value}
                  sessionDetailData={sessionData}
                  sendUrl={inviteUrl}
                  agentUrl={agentUrl}
                  message={smsTemplate}
                  inviteUser={inviteUser}
                  isUploadOnlySessionValue={isUploadOnlySessionValue}
                  isSessionOwner={isSessionOwner()}
                  isForSaskTail={!isEmpty(dataForSession)}
                />
              )}
              {sessionData?.features?.SHOWMEOBSERVER === "1" &&
                selectedTab !== SEND_LINK_TYPES.Smartglass.value &&
                !isUploadOnlySessionValue && (
                  <div className="radio-btn-list inline join-option-radio">
                    <RadioButton
                      value={inviteUser}
                      checked={inviteUser === INVITE_USERS_TYPE.GUEST}
                      onChange={() => setInviteUser(INVITE_USERS_TYPE.GUEST)}
                      label={localisedValues[INVITE_USERS_TYPE.GUEST]}
                    />
                    <RadioButton
                      value={inviteUser}
                      checked={inviteUser === INVITE_USERS_TYPE.SILENT_OBSERVER}
                      onChange={() =>
                        setInviteUser(INVITE_USERS_TYPE.SILENT_OBSERVER)
                      }
                      label={localisedValues[INVITE_USERS_TYPE.SILENT_OBSERVER]}
                      showTooltip={true}
                      tooltipValue={localisedValues["silent_observer_tooltip"]}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>}
    </>
  );
};

export default SendCode;

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { PopupModal } from '../../UI/Modal/Modal'
import { Localize } from '../Localize'

const DiscardModal = ({ onCancel, onDiscard, onSave, isSaveBtnDisabled }) => {

    const { putSessionReferenceLoading, putAssigneeChangeLoading } = useSelector((state) => state.sessionDetail)
    const { language } = useSelector((state) => state)

    const [localisedValues, setLocalisedValues] = useState({});

    useEffect(() => {
        const curLang = language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
    }, [language.selectedlanguage]);

    return (
        <PopupModal
            closeModal={onCancel}
            modalClass="modal-small alert-cms-modal"
            dialogClass="modal-dialog-centered"
            headerContent={
                <h5 className="modal-title" data-testid="modalHeaderTitle">
                    {localisedValues['discard_change']}
                </h5>
            }
            bodyContent={
                <div className="cms-modal-text">
                    <p>{localisedValues['discard_change_desc1']}</p>
                    <p><strong>{localisedValues['note']}:</strong> {localisedValues['discard_change_desc2']}</p>
                </div>
            }
            footerContent={
                <div className="button-inline d-flex">
                    <div className="button">
                        <button type="button" className="btn btn-outline-secondary font-weight-500" data-bs-dismiss="modal" onClick={onCancel}>{localisedValues['user_cancel']}</button>
                    </div>
                    <div className="button">
                        <button type="button" className="btn btn-outline-secondary font-weight-500" data-bs-dismiss="modal" onClick={onDiscard}>{localisedValues['discard_buton']}</button>
                    </div>
                    <div className="button">
                        <button type="button" className="btn btn-primary btn-with-icon font-weight-500" onClick={onSave} disabled={isSaveBtnDisabled || putSessionReferenceLoading || putAssigneeChangeLoading}>
                            {(putSessionReferenceLoading || putAssigneeChangeLoading) && <span className="btn-loader-icon" data-testid='loaderSpinner'>
                                <span className="for-icon icon-loader spin"></span>
                            </span>}
                            {localisedValues['save_button']}</button>
                    </div>
                </div>
            }
            backdrop={'static'}
            disablekeyboard={true}
        />)
}

DiscardModal.propTypes = {
    onCancel: PropTypes.func,
    onDiscard: PropTypes.func,
    onSave: PropTypes.func,
    isSaveBtnDisabled: PropTypes.bool,
}

export default DiscardModal
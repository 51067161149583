/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { PopupModal } from "../UI/Modal/Modal";
import { FormikInputGroup } from "../UI/TextInput/FormikInputGroup";
import {SSO_TOKEN_URL} from "../../constants/Constants"
import { handleErrorMsgToastVisiblity } from "../../shared/utility";
import PublicVariables from "../../constants/PublicVariables";

const SSOLoginModal = (props) => {
    const [isSubmittingForSSO, setIsSubmittingForSSO] = useState(false);

    useEffect(() => {
        setIsSubmittingForSSO(props.login.verifyDomainLoading);
        if(props.login.verifyDomainData){
            if (props.login.verifyDomainData.integrations === true && props.login.verifyDomainData.samlIdentity) {
                window.location.href = SSO_TOKEN_URL + props.login.verifyDomainData.samlIdentity;
            } else{
                handleErrorMsgToastVisiblity(props.localisedValues["login_with_sso_not_allow"]);        
            }
        }
        // eslint-disable-next-line
    }, [props.login.verifyDomainData, props.login.verifyDomainLoading]);  

    const closeModal = () =>{
        props.onClose(false)
    }
    return (
        <Formik
            initialValues={{
                domainName: ''
            }}
            validationSchema={
                Yup.object({
                    domainName: Yup.string()
                    .required(props.localisedValues['domain_val_msg'])
                    .max(30, props.localisedValues['crt_acnt_limit_character_msg'])
                })
            }
            onSubmit={(values, { _setSubmitting }) => {
                setTimeout(() => {
                    console.log(values);
                    PublicVariables.isVerifyDomainClicked = true;
                    props.verifyDomain(values.domainName);
                }, 400)
            }}
        >
                {formik => (
                    <PopupModal
                        closeModal={closeModal}
                        modalClass="modal-small alert-cms-modal"
                        dialogClass="modal-dialog-centered"
                        headerContent={
                            <h4 className="modal-title">{props.localisedValues['verify_domain']}</h4>
                        }
                        bodyContent={
                            <div className="reset-password-form">
                                <FormikInputGroup
                                    id="domainName"
                                    type="text"
                                    className="height-46 focus-font-bold"
                                    placeholder={props.localisedValues['enter_domain_name']}
                                    touched={formik.touched.domainName}
                                    error={formik.errors.domainName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} 
                                />
                            </div>
                        }
                        cancelButton={false}
                        confirmText={props.localisedValues['verify']}
                        disableConfirm={isSubmittingForSSO || !formik.isValid}
                        showLoader={isSubmittingForSSO}
                        onConfirm={formik.submitForm}
                    />
                )}
                </Formik>
    )
}

SSOLoginModal.propTypes = {
    login: PropTypes.object,
    localisedValues: PropTypes.object,
    onClose: PropTypes.func,
    verifyDomain: PropTypes.func
}

export default SSOLoginModal;


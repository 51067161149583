export const GET_SESSION_NOTES = {
    START: "GET_SESSION_NOTES_START",
    SUCCESS: "GET_SESSION_NOTES_SUCCESS",
    ERROR: "GET_SESSION_NOTES_ERROR",
};

export const GET_SESSION_DETAIL = {
    START: "GET_SESSION_DETAIL_START",
    UPDATE: "SESSION_DETAIL_UPDATE",
    SUCCESS: "GET_SESSION_DETAIL_SUCCESS",
    ERROR: "GET_SESSION_DETAIL_ERROR",
    RESET: "GET_SESSION_DETAIL_RESET"
}

export const GET_SESSION_IMAGES = {
    START: "GET_SESSION_IMAGES_START",
    SUCCESS: "GET_SESSION_IMAGES_SUCCESS",
    ERROR: "GET_SESSION_IMAGES_ERROR",
}

export const GET_SESSION_RECORDINGS = {
    START: "GET_SESSION_RECORDINGS_START",
    SUCCESS: "GET_SESSION_RECORDINGS_SUCCESS",
    ERROR: "GET_SESSION_RECORDINGS_ERROR",
}

export const GET_SESSION_WORDDOC = {
    START: "GET_SESSION_WORDDOC_START",
    SUCCESS: "GET_SESSION_WORDDOC_SUCCESS",
    ERROR: "GET_SESSION_WORDDOC_ERROR",
}

export const GET_SESSION_INVITATIONS = {
    START: "GET_SESSION_INVITATIONS_START",
    SUCCESS: "GET_SESSION_INVITATIONS_SUCCESS",
    ERROR: "GET_SESSION_INVITATIONS_ERROR",
}

export const PUT_ASSIGNEE_CHANGE = {
    START: "PUT_ASSIGNEE_CHANGE_START",
    SUCCESS: "PUT_ASSIGNEE_CHANGE_SUCCESS",
    ERROR: "PUT_ASSIGNEE_CHANGE_ERROR",
}

export const PUT_END_SESSION = {
    START: "PUT_END_SESSION_START",
    SUCCESS: "PUT_END_SESSION_SUCCESS",
    ERROR: "PUT_END_SESSION_ERROR",
}

export const PUT_ARCHIVE_SESSION = {
    START: "PUT_ARCHIVE_SESSION_START",
    SUCCESS: "PUT_ARCHIVE_SESSION_SUCCESS",
    ERROR: "PUT_ARCHIVE_SESSION_ERROR",
}

export const GET_ASSIGNEE_LIST = {
    START: "GET_ASSIGNEE_LIST_START",
    SUCCESS: "GET_ASSIGNEE_LIST_SUCCESS",
    ERROR: "GET_ASSIGNEE_LIST_ERROR",
}

export const GET_SESSION_FILES = {
    START: "GET_SESSION_FILES_START",
    SUCCESS: "GET_SESSION_FILES_SUCCESS",
    ERROR: "GET_SESSION_FILES_ERROR",
}

export const DELETE_SESSION_CHAT_FILES = {
    START: "DELETE_SESSION_CHAT_FILES_START",
    SUCCESS: "DELETE_SESSION_CHAT_FILES_SUCCESS",
    ERROR: "DELETE_SESSION_CHAT_FILES_ERROR",
    RESET: "DELETE_SESSION_CHAT_FILES_RESET",
}

export const DELETE_SESSION_OCR_FILES = {
    START: "DELETE_SESSION_OCR_FILES_START",
    SUCCESS: "DELETE_SESSION_OCR_FILES_SUCCESS",
    ERROR: "DELETE_SESSION_OCR_FILES_ERROR",
    RESET: "DELETE_SESSION_OCR_FILES_RESET",
}

export const DELETE_SESSION_IMAGES = {
    START: "DELETE_SESSION_IMAGES_START",
    SUCCESS: "DELETE_SESSION_IMAGES_SUCCESS",
    ERROR: "DELETE_SESSION_IMAGES_ERROR",
    RESET: "DELETE_SESSION_IMAGES_RESET",
}

export const DELETE_SESSION_RECORDINGS = {
    START: "DELETE_SESSION_RECORDINGS_START",
    SUCCESS: "DELETE_SESSION_RECORDINGS_SUCCESS",
    ERROR: "DELETE_SESSION_RECORDINGS_ERROR",
    RESET: "DELETE_SESSION_RECORDINGS_RESET",
}

export const MODIFY_SESSION_TABS_DETAILS = {
    RESET: "MODIFY_SESSION_TABS_DETAILS",
}

export const PUT_SESSION_REFERENCE_CHANGE = {
    START: "PUT_SESSION_REFERENCE_CHANGE_START",
    SUCCESS: "PUT_SESSION_REFERENCE_CHANGE_SUCCESS",
    ERROR: "PUT_SESSION_REFERENCE_CHANGE_ERROR",
    RESET: "PUT_SESSION_REFERENCE_CHANGE_RESET",
}

const defaultGetSessionDetailsState = {
    getSessionDetailsLoading: false,
    getSessionDetailsData: false,
    getSessionDetailsError: false,
}

const defaultGetSessionNotes = {
    getSessionNotesLoading: false,
    getSessionNotesData: false,
    getSessionNotesError: false,
};

const defaultGetSessionImagesState = {
    getSessionImagesLoading: false,
    getSessionImagesData: false,
    getSessionImagesError: false,
}

const defaultGetSessionRecordings = {
    getSessionRecordingsLoading: false,
    getSessionRecordingsData: false,
    getSessionRecordingsError: false,
}

const defaultGetSessionWordDoc = {
    getSessionWordDocLoading: false,
    getSessionWordDocData: false,
    getSessionWordDocError: false,
}

const defaultGetSessionInvitations = {
    getSessionInvitationsLoading: false,
    getSessionInvitationsData: false,
    getSessionInvitationsError: false,
};

const defaultGetAssigneeList = {
    getAssigneeListLoading: false,
    getAssigneeListData: false,
    getAssigneeListError: false,
}

const defaultPutAssigneeChange = {
    putAssigneeChangeLoading: false,
    putAssigneeChangeData: false,
    putAssigneeChangeError: false,
}

const defaultPutArchiveSession = {
    putArchiveSessionLoading: false,
    putArchiveSessionData: false,
    putArchiveSessionError: false,
}

const defaultPutEndSession = {
    putEndSessionLoading: false,
    putEndSessionData: false,
    putEndSessionError: false,
}

const defaultGetSessionFiles = {
    getSessionFilesLoading: false,
    getSessionFilesData: false,
    getSessionFilesError: false,
};

const defaultDeleteSessionChatFiles = {
    deleteSessionChatFilesLoading: false,
    deleteSessionChatFilesData: false,
    deleteSessionChatFilesError: false,
};

const defaultDeleteSessionOcrFiles = {
    deleteSessionOcrFilesLoading: false,
    deleteSessionOcrFilesData: false,
    deleteSessionOcrFilesError: false,
};

const defaultDeleteSessionImagesState = {
    deleteSessionImagesLoading: false,
    deleteSessionImagesData: false,
    deleteSessionImagesError: false,
}

const defaultDeleteSessionRecordingsState = {
    deleteSessionRecordingsLoading: false,
    deleteSessionRecordingsData: false,
    deleteSessionRecordingsError: false,
}

const defaultPutSessionReferenceState = {
    putSessionReferenceLoading: false,
    putSessionReferenceData: false,
    putSessionReferenceError: false,
}

export const defaultState = {
    ...defaultGetSessionDetailsState,
    ...defaultGetSessionNotes,
    ...defaultGetSessionImagesState,
    ...defaultGetSessionRecordings,
    ...defaultGetSessionInvitations,
    ...defaultGetAssigneeList,
    ...defaultGetSessionFiles,
    ...defaultDeleteSessionChatFiles,
    ...defaultDeleteSessionOcrFiles,
    ...defaultPutAssigneeChange,
    ...defaultPutArchiveSession,
    ...defaultPutEndSession,
    ...defaultGetSessionFiles,
    ...defaultDeleteSessionImagesState,
    ...defaultGetSessionWordDoc,
    ...defaultDeleteSessionRecordingsState,
    ...defaultPutSessionReferenceState
}

export const EVENTS = {
    GET_SESSION_DETAIL: 'GET_SESSION_DETAIL',
    GET_SESSION_NOTES: "GET_SESSION_NOTES",
    GET_ASSIGNEE_LIST: 'GET_ASSIGNEE_LIST',
    PUT_ASSIGNEE_CHANGE: 'PUT_ASSIGNEE_CHANGE',
    GET_SESSION_IMAGES: "GET_SESSION_IMAGES",
    GET_SESSION_RECORDINGS: "GET_SESSION_RECORDINGS",
    GET_SESSION_WORDDOC: "GET_SESSION_WORDDOC",
    GET_SESSION_INVITATIONS: 'GET_SESSION_INVITATIONS',
    GET_SESSION_FILES: 'GET_SESSION_FILES',
    DELETE_SESSION_CHAT_FILES: "DELETE_SESSION_CHAT_FILES",
    DELETE_SESSION_OCR_FILES: "DELETE_SESSION_OCR_FILES",
    DELETE_SESSION_IMAGES: "DELETE_SESSION_IMAGES",
    DELETE_SESSION_RECORDINGS: 'DELETE_SESSION_RECORDINGS',
    PUT_ARCHIVE_SESSION: "PUT_ARCHIVE_SESSION",
    PUT_END_SESSION: "PUT_END_SESSION",
    MODIFY_SESSION_TABS_DETAILS: "MODIFY_SESSION_TABS_DETAILS",
    PUT_SESSION_REFERENCE_CHANGE: 'PUT_SESSION_REFERENCE_CHANGE',
    RESET_SESSIONDETAIL_REDUCER: 'RESET_SESSIONDETAIL_REDUCER'
}

const sessionDetailReducer = (state = defaultState, action = {}) => {
    switch (action.event) {
        case EVENTS.RESET_SESSIONDETAIL_REDUCER:
            return defaultState;
        case EVENTS.GET_SESSION_DETAIL:
            if (action.type === GET_SESSION_DETAIL.RESET) {
                return { ...state, ...defaultGetSessionDetailsState };
            }
            return {
                ...state,
                getSessionDetailsLoading: action.type === GET_SESSION_DETAIL.START,
                getSessionDetailsData: (action.type === GET_SESSION_DETAIL.SUCCESS || action.type === GET_SESSION_DETAIL.UPDATE) ? action.payload : false,
                getSessionDetailsError: action.type === GET_SESSION_DETAIL.ERROR ? action.payload : false,
            };
        case EVENTS.GET_SESSION_NOTES:
            return {
                ...state,
                getSessionNotesLoading:
                    action.type === GET_SESSION_NOTES.START ,
                getSessionNotesData:
                    action.type === GET_SESSION_NOTES.SUCCESS ? action.payload : false,
                getSessionNotesError:
                    action.type === GET_SESSION_NOTES.ERROR ? action.payload : false,
            };
        case EVENTS.GET_SESSION_IMAGES:
            return {
                ...state,
                getSessionImagesLoading:
                    action.type === GET_SESSION_IMAGES.START ,
                getSessionImagesData:
                    action.type === GET_SESSION_IMAGES.SUCCESS ? action.payload : false,
                getSessionImagesError:
                    action.type === GET_SESSION_IMAGES.ERROR ? action.payload : false,
            };
        case EVENTS.GET_SESSION_RECORDINGS:
            return {
                ...state,
                getSessionRecordingsLoading:
                    action.type === GET_SESSION_RECORDINGS.START ,
                getSessionRecordingsData:
                    action.type === GET_SESSION_RECORDINGS.SUCCESS ? action.payload : false,
                getSessionRecordingsError:
                    action.type === GET_SESSION_RECORDINGS.ERROR ? action.payload : false,
            };
        case EVENTS.GET_SESSION_WORDDOC:
            return {
                ...state,
                getSessionWordDocLoading:
                    action.type === GET_SESSION_WORDDOC.START ,
                getSessionWordDocData:
                    action.type === GET_SESSION_WORDDOC.SUCCESS ? action.payload : false,
                getSessionWordDocError:
                    action.type === GET_SESSION_WORDDOC.ERROR ? action.payload : false,
            };
        case EVENTS.GET_SESSION_INVITATIONS:
            return {
                ...state,
                getSessionInvitationsLoading: action.type === GET_SESSION_INVITATIONS.START ,
                getSessionInvitationsData: action.type === GET_SESSION_INVITATIONS.SUCCESS ? action.payload : false,
                getSessionInvitationsError: action.type === GET_SESSION_INVITATIONS.ERROR ? action.payload : false,
            };
        case EVENTS.GET_ASSIGNEE_LIST:
            return {
                ...state,
                getAssigneeListLoading: action.type === GET_ASSIGNEE_LIST.START ,
                getAssigneeListData: action.type === GET_ASSIGNEE_LIST.SUCCESS ? action.payload : false,
                getAssigneeListError: action.type === GET_ASSIGNEE_LIST.ERROR ? action.payload : false,
            };
        case EVENTS.PUT_ASSIGNEE_CHANGE:
            return {
                ...state,
                putAssigneeChangeLoading: action.type === PUT_ASSIGNEE_CHANGE.START ,
                putAssigneeChangeData: action.type === PUT_ASSIGNEE_CHANGE.SUCCESS ? action.payload : false,
                putAssigneeChangeError: action.type === PUT_ASSIGNEE_CHANGE.ERROR ? action.payload : false,
            };
        case EVENTS.PUT_ARCHIVE_SESSION:
            return {
                ...state,
                putArchiveSessionLoading: action.type === PUT_ARCHIVE_SESSION.START ,
                putArchiveSessionData: action.type === PUT_ARCHIVE_SESSION.SUCCESS ? action.payload : false,
                putArchiveSessionError: action.type === PUT_ARCHIVE_SESSION.ERROR ? action.payload : false,
            };
        case EVENTS.PUT_END_SESSION:
            return {
                ...state,
                putEndSessionLoading: action.type === PUT_END_SESSION.START ,
                putEndSessionData: action.type === PUT_END_SESSION.SUCCESS ? action.payload : false,
                putEndSessionError: action.type === PUT_END_SESSION.ERROR ? action.payload : false,
            };
        case EVENTS.GET_SESSION_FILES:
            return {
                ...state,
                getSessionFilesLoading: action.type === GET_SESSION_FILES.START ,
                getSessionFilesData: action.type === GET_SESSION_FILES.SUCCESS ? action.payload : false,
                getSessionFilesError: action.type === GET_SESSION_FILES.ERROR ? action.payload : false,
            };
        case EVENTS.DELETE_SESSION_CHAT_FILES:
            if (action.type === DELETE_SESSION_CHAT_FILES.RESET) {
                return { ...state, ...defaultDeleteSessionChatFiles };
            }
            else {
                return {
                    ...state,
                    deleteSessionChatFilesLoading: action.type === DELETE_SESSION_CHAT_FILES.START ,
                    deleteSessionChatFilesData: action.type === DELETE_SESSION_CHAT_FILES.SUCCESS ? action.payload : false,
                    deleteSessionChatFilesError: action.type === DELETE_SESSION_CHAT_FILES.ERROR ? action.payload : false,
                };
            }
        case EVENTS.DELETE_SESSION_OCR_FILES:
            if (action.type === DELETE_SESSION_OCR_FILES.RESET) {
                return { ...state, ...defaultDeleteSessionOcrFiles };
            }
            else {
                return {
                    ...state,
                    deleteSessionOcrFilesLoading: action.type === DELETE_SESSION_OCR_FILES.START ,
                    deleteSessionOcrFilesData: action.type === DELETE_SESSION_OCR_FILES.SUCCESS ? action.payload : false,
                    deleteSessionOcrFilesError: action.type === DELETE_SESSION_OCR_FILES.ERROR ? action.payload : false,
                };
            }
        case EVENTS.DELETE_SESSION_IMAGES:
            if (action.type === DELETE_SESSION_IMAGES.RESET) {
                return { ...state, ...defaultDeleteSessionImagesState };
            }
            else {
                return {
                    ...state,
                    deleteSessionImagesLoading: action.type === DELETE_SESSION_IMAGES.START ,
                    deleteSessionImagesData: action.type === DELETE_SESSION_IMAGES.SUCCESS ? action.payload : false,
                    deleteSessionImagesError: action.type === DELETE_SESSION_IMAGES.ERROR ? action.payload : false,
                };
            }
        case EVENTS.DELETE_SESSION_RECORDINGS:
                if (action.type === DELETE_SESSION_RECORDINGS.RESET) {
                    return { ...state, ...defaultGetSessionRecordings };
                }
                else {
                    return {
                        ...state,
                        deleteSessionRecordingsLoading: action.type === DELETE_SESSION_RECORDINGS.START ,
                        deleteSessionRecordingsData: action.type === DELETE_SESSION_RECORDINGS.SUCCESS ? action.payload : false,
                        deleteSessionRecordingsError: action.type === DELETE_SESSION_RECORDINGS.ERROR ? action.payload : false,
                    };
                }

        case EVENTS.PUT_SESSION_REFERENCE_CHANGE:
            if (action.type === PUT_SESSION_REFERENCE_CHANGE.RESET) {
                return { ...state, ...defaultPutSessionReferenceState };
            }
            else {
                return {
                    ...state,
                    putSessionReferenceLoading: action.type === PUT_SESSION_REFERENCE_CHANGE.START ,
                    putSessionReferenceData: action.type === PUT_SESSION_REFERENCE_CHANGE.SUCCESS ? action.payload : false,
                    putSessionReferenceError: action.type === PUT_SESSION_REFERENCE_CHANGE.ERROR ? action.payload : false,
                };
            }

        case EVENTS.MODIFY_SESSION_TABS_DETAILS:
            if (action.type === MODIFY_SESSION_TABS_DETAILS.RESET) {
                return { ...defaultState };
            }
            return state;
            
        default:
            return state;
    }
}

export default sessionDetailReducer;


import { PopupModal } from "../../UI/Modal/Modal";
import PropTypes from "prop-types";

const DeleteModal = ({
  setIsModalVisible,
  selectedFiles,
  loading,
  handleOnDelete,
  localisedValues,
  headerTitleLocalisedKey,
  bodyContentLocalisedKey,
}) => {
  return (
    <PopupModal
      closeModal={() => setIsModalVisible(false)}
      modalClass="modal-small delete-item-modal alert-cms-modal"
      dialogClass="modal-dialog-centered"
      headerContent={
        <h4 className="modal-title" data-testid="modalHeaderTitle">
          {localisedValues[headerTitleLocalisedKey].replace(
            "<count>",
            selectedFiles.length
          )}
        </h4>
      }
      bodyContent={
        <div className="cms-modal-text">
          <p>
            {`${localisedValues[bodyContentLocalisedKey].replace(
              "<count>",
              selectedFiles.length
            )} ${localisedValues["delete_pupup_commontext"]}`}
          </p>
        </div>
      }
      cancelText={localisedValues["user_cancel"]}
      confirmText={localisedValues["delete"]}
      disableConfirm={loading}
      showLoader={loading}
      onConfirm={handleOnDelete}
      backdrop={"static"}
      disablekeyboard={true}
    />
  );
};

DeleteModal.propTypes = {
  setIsModalVisible: PropTypes.func,
  selectedFiles: PropTypes.object,
  loading: PropTypes.bool,
  handleOnDelete: PropTypes.func,
  localisedValues: PropTypes.object,
  headerTitleLocalisedKey: PropTypes.string,
  bodyContentLocalisedKey: PropTypes.string,
};

export default DeleteModal;

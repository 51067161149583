import { useEffect, useState } from "react";
import DetectRTC from "detectrtc";
import safariBrowserImage from "../../assets/images/safari.svg";
import chromeBrowserImage from "../../assets/images/chrome.svg";
import firefoxBrowserImage from "../../assets/images/firefox.svg";
import { copyValue, desktopSupportedBrowser, isChromeBrowser, isSafariBrowser, isValidAndroidBrowser, isValidDevice } from "../../shared/utility";
import { PopupModal } from "../UI/Modal/Modal";

const UnSupportedBrowser = (props) => {
    const [isSupportedBrowser, setIsSupportedBrowser] = useState(true);
    const [unSupportedBrowserDesc, setUnSupportedBrowserDesc] = useState(true);

    const checkSupportedBrowsers = () => {
        let isSupported = true;
        let description = "";
        // if ((navigator.userAgent.match(/iPhone|iPad|iPod/i)) || (navigator.platform && navigator.platform == "MacIntel" &&
        //     navigator.maxTouchPoints && navigator.maxTouchPoints > 2)) {
        //     $('body').addClass("iosbodyclass");
        // }
        if(DetectRTC.isMobileDevice)
        {
            //mobile devices
            if (!isValidDevice()) {
                description = <div className="cms-modal-text">
                    <p>Install the latest version of Chrome/Safari browser try again.</p>
                </div>
                isSupported = false;
            }
            else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) 
            {
                //iOS 
                if(!isSafariBrowser()){
                    description = <div className="cms-modal-text">
                        <p>Open this link in the latest version of Safari or Chrome Browser and try again.</p>
                    </div>
                    isSupported = false;
                }
                else if(!navigator.mediaDevices){
                    isSupported = false;
                    description = <div className="cms-modal-text">
                        <p>Open this link in the latest version of Safari Browser and try again.</p>
                    </div>
                }
            }
            else if(DetectRTC.osName == "Android")
            {
                //adnroid
                if(!isChromeBrowser()){
                    description = <div className="cms-modal-text">
                        <p>Open this link in the latest version of Google Chrome Browser and try again.</p>
                    </div>
                    isSupported = false;
                }    
                if(!isValidAndroidBrowser()){
                    description = <div className="cms-modal-text">
                        <p>Install the latest version of Chrome/Safari browser try again.</p>
                    </div>
                    isSupported = false;
                }
            }
        }else{
            //desktop = chrome,safari,FF
            if(!desktopSupportedBrowser()){
                description = <div className="cms-modal-text">
                    <p>
                        Some features are not supported in this browser. Try one of these options for a better experience.
                    </p>
                    <div className="unsupported-browser-list">
                        <div className="unsupported-browser-col">
                            <div className="unsupported-browser-box">
                                <div className="browser-image">
                                    <img src={safariBrowserImage} alt="safari" />
                                </div>
                                <div className="browser-name">
                                    <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">Safari</a>
                                </div>
                            </div>
                        </div>
                        <div className="unsupported-browser-col">
                            <div className="unsupported-browser-box">
                                <div className="browser-image">
                                    <img src={chromeBrowserImage} alt="chrome" />
                                </div>
                                <div className="browser-name">
                                    <a href="https://www.google.com/chrome/?brand=CHBD&gclid=Cj0KCQiA2vjuBRCqARIsAJL5a-LdXchgOAJa90QQRaIiRj5x_EA79eUb9Eb9zj-FAQ6uoZhKX2tjX-8aApPPEALw_wcB&gclsrc=aw.ds" target="_blank" rel="noopener noreferrer">Chrome</a>
                                </div>
                            </div>
                        </div>
                        <div className="unsupported-browser-col">
                            <div className="unsupported-browser-box">
                                <div className="browser-image">
                                    <img src={firefoxBrowserImage} alt="Firefox" />
                                </div>
                                <div className="browser-name">
                                    <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">Firefox</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                isSupported=false;
            }
        }
        setUnSupportedBrowserDesc(description);
        setIsSupportedBrowser(isSupported);
    }

    useEffect(()=>{
        checkSupportedBrowsers();
    }, []);

    const copyCurrentLink = () => {
        copyValue(window.location.href, "Link Copied");
    }

    return (
        <>
            {!isSupportedBrowser &&
                <PopupModal
                    closeModal={() => setIsSupportedBrowser(true)}
                    modalClass="modal-medium alert-cms-modal"
                    dialogClass="modal-dialog-centered"
                    closeButton={false}
                    headerContent={<h4 className="modal-title">Unsupported Browser</h4>}
                    bodyContent={unSupportedBrowserDesc}
                    footerContent={<>
                        <div className="button">
                            <button type="button" className="btn btn-link text-dec-none mw-initial font-weight-500" onClick={copyCurrentLink}>Copy link</button>
                        </div>
                        <div className="button">
                            <button type="button" className="btn btn-primary font-weight-500" onClick={() => setIsSupportedBrowser(true)}>Continue anyway</button>
                        </div>
                    </>}
                    footerInlineClass="align-items-center justify-content-between width-100"
                    backdrop='static'
                />
            }
        </>
    )
}

export default UnSupportedBrowser;
import { Card } from "../UI/Card/Card";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from 'yup';
import clsx from "clsx";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { FormikInputGroup } from "../UI/TextInput/FormikInputGroup";
import { SubmitButton } from "../UI/Button/Button";
import { getDomainURL } from "../../shared/utility";
import { checkEmailForSignUp, handleRegisterUser } from "../../actions/signupAction"
import storage from "../../services/storage";
import { PopupModal } from "../UI/Modal/Modal";
import { RouteName, SignUpErrorCode1036, LOCAL_STORAGE_KEYS } from "../../constants/Constants";
import history from "../../history";
import "./SignUp.scss";
import RegisterUser from "./RegisterUser";
import { EVENTS } from "../../reducers/signUp-reducer";
import { Localize } from "./Localize";

const SignUp = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [verifiedUserDetails, setVerifiedUserDetails] = useState(false);
    const [isUserVerified, setIsUserVerified] = useState(false);
    const [errorInVerificationDetails, setErrorInVerificationDetails] = useState({});
    const [showStatusErrorModal, setShowStatusErrorModal] = useState(false);
    const [localisedValues, setLocalisedValues] = useState({});
    const [isCustomDomain, setIsCustomDomain] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(() => {
        if (getDomainURL() === null || getDomainURL() === "") {
            setIsCustomDomain(false);
        } else {
            setIsCustomDomain(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const closeStatusErrorModal = () => {
        setShowStatusErrorModal(false);
    }

    const popupModalDismiss = () => {
        closeStatusErrorModal();
    }

    const redirectToLogin = () => {
        closeStatusErrorModal();
        cleanSignUpProps();
        history.push(RouteName.LOGIN);
    }

    const cleanSignUpProps = () => {
        props.cleanSignUpData();
    }

    const statusErrorModalHeaderContent = (
        <>
            {errorInVerificationDetails && errorInVerificationDetails.code == SignUpErrorCode1036 ?
                (<h5 className="modal-title">{localisedValues["already_registerd"]}</h5>) : (<h5 className="modal-title">{localisedValues["invalid_email_user_id"]}</h5>)
            }
        </>
    );

    const statusErrorModalBodyContent = (
        <>
            {errorInVerificationDetails && errorInVerificationDetails.code == SignUpErrorCode1036 ?
                (<div className="cms-modal-text"><p>{localisedValues["user_seems_already_registerd"]}</p></div>) : (<div className="cms-modal-text"><p>{localisedValues["invalid_email_user_id_msg_first"] + ' ' + localisedValues["invalid_email_user_id_msg_second"]}</p></div>)
            }
        </>
    );

    const statusErrorModalFooterContent = (
        <>
            <div className="button">
                <button type="button" className={clsx("btn font-weight-500", errorInVerificationDetails && errorInVerificationDetails.code == SignUpErrorCode1036 ? "btn-outline-secondary" : "btn-primary")} onClick={popupModalDismiss}>{localisedValues["dismiss"]}</button>
            </div>
            {errorInVerificationDetails && errorInVerificationDetails.code == SignUpErrorCode1036 &&
                <div className="button">
                    <button type="button" className="btn btn-primary font-weight-500" onClick={redirectToLogin}>{localisedValues["continue"]}</button>
                </div>
            }
        </>
    );

    const registerUserHandler = (userDetails) => {
        props.handleRegisterUser(userDetails)
    };

    useEffect(() => {
        if (props.signUp.checkingSignUpEmailLoading) {
            setIsSubmitting(props.signUp.checkingSignUpEmailLoading);
        }
        if (props.signUp.checkingSignUpEmailData) {
            storage.set(LOCAL_STORAGE_KEYS.COMPANY_TOKEN, props.signUp.checkingSignUpEmailData.COMPANYTOKEN);
            setVerifiedUserDetails(props.signUp.checkingSignUpEmailData);
            setIsUserVerified(true);
            setIsSubmitting(false);
        }
        if (props.signUp.checkingSignUpEmailError) {
            setShowStatusErrorModal(true);
            setIsSubmitting(false);
            setErrorInVerificationDetails(props.signUp.checkingSignUpEmailError)
        }
        // eslint-disable-next-line
    }, [props.signUp.checkingSignUpEmailLoading, props.signUp.checkingSignUpEmailData, props.signUp.checkingSignUpEmailError])

    const checkEmailForSignUpHandler = (values) => {
        const email = values.email.trim()
        setEmailAddress(email);
        storage.remove(LOCAL_STORAGE_KEYS.COMPANY_TOKEN);
        props.checkEmailForSignUp(email);
    };

    const goBackVerifyAccount = () => {
        setIsUserVerified(false);
        setVerifiedUserDetails(false);
    };

    return (
        <>

            {!isUserVerified && <div className="login-section-box custom-scroll-div">
                <div className="login-section-in">
                    <Card>
                        <Formik
                            initialValues={{
                                email: emailAddress
                            }}
                            validationSchema={
                                Yup.object({
                                    email: !isCustomDomain ?
                                        Yup.string().required(localisedValues["enter_valid_email"])
                                        : Yup.string().email(localisedValues["valid_email_user_id"]).max(255, localisedValues["limit_exceeded"]).required(localisedValues["valid_email_user_id"])
                                })
                            }
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    checkEmailForSignUpHandler(values)
                                    setSubmitting(false);
                                }, 400)
                            }}
                        >
                            {formik => (
                                    <Form>
                                        <div className="login-logo-subtext text-center">
                                            <h3>{localisedValues['new_account']}</h3>
                                            <p>{!isCustomDomain ? localisedValues['sign_up_with_work_email']  : localisedValues['sign_up_with_work_email_or_userid']}</p>
                                        </div>
                                        <FormikInputGroup
                                            id='email'
                                            type={"text"}
                                            className="height-46 focus-font-bold"
                                            placeholder={!isCustomDomain ? localisedValues["enter_your_work_email"] : localisedValues["enter_your_work_email_userid"]}
                                            touched={formik.touched.email}
                                            error={formik.errors.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        ></FormikInputGroup>

                                        <div className="button-inline text-center padding-top-10 d-flex justify-content-center">
                                            <div className="button">
                                                <SubmitButton className="btn btn-primary font-weight-500" id="SignUp" name={localisedValues['sign_up']} isSubmitting={isSubmitting} disable={isSubmitting || !formik.isValid || (formik.values.email === "" && formik.errors.email === undefined)} showLoader={true} ></SubmitButton>
                                            </div>
                                        </div>
                                        <div className="login-bottom-text">
                                            <div className="have-an-account text-center">
                                                <span data-testid="haveAnAccount">{localisedValues["already_have_account"]}</span> <a href={void(0)} rel="noreferrer" onClick={redirectToLogin} className="text-dec-none pointer">{localisedValues["login"]}</a>
                                            </div>
                                        </div>
                                    </Form>
                            )}
                        </Formik>
                    </Card>
                </div>
            </div>}

            {showStatusErrorModal && !isUserVerified &&
                <PopupModal
                    closeModal={closeStatusErrorModal}
                    modalClass="modal-small alert-cms-modal"
                    dialogClass="modal-dialog-centered"
                    headerContent={statusErrorModalHeaderContent}
                    bodyContent={statusErrorModalBodyContent}
                    footerContent={statusErrorModalFooterContent}
                    backdrop='static'
                />
            }

            {isUserVerified &&
                <RegisterUser
                    userDetails={verifiedUserDetails}
                    registerProps={props.signUp}
                    userEmail={emailAddress}
                    goBackVerifyAccount={goBackVerifyAccount}
                    handleRegisterUser={registerUserHandler}
                    cleanSignupData={cleanSignUpProps}
                    localisedValues={localisedValues}
                />}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        signUp: state.signUp,
        language: state.language
    }
}

const mapDispatchToPropsSignUp = (dispatch) => {
    return {
        checkEmailForSignUp: (req) => dispatch(checkEmailForSignUp(req)),
        handleRegisterUser: (req) => dispatch(handleRegisterUser(req)),
        cleanSignUpData: () => dispatch({
            event: EVENTS.CLEAN_SIGNUP_ERROR,
            type: ''
        })
    }
}

SignUp.propTypes = {
    language: PropTypes.object,
    cleanSignUpData: PropTypes.func,
    handleRegisterUser: PropTypes.func,
    signUp: PropTypes.object,
    checkEmailForSignUp: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToPropsSignUp)(SignUp);
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
/* global BlitzzCobrowse */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import notificationAudioMp3 from '../../assets/audio/notification.mp3';
import notificationAudioOgg from '../../assets/audio/notification.ogg';
import clsx from "clsx";
import "./Header.scss";

import {
    BillingURLPath,
    ContactURL,
    DefaultLanguage,
    LocaliseLanguageArray,
    PausedKey,
    RouteName,
    LOCAL_STORAGE_KEYS,
    SETTINGS_URL,
    REPORTS_URL,
    JOURNEY_URL,
    BILLING_URL,
    USER_ROLE_CODE,
    errorStatusCode,
    COBROWSE_URL,
    LOCAL_STORAGE_KEYS_ADMIN,
    COBROWSE_SDK_URL,
    COBROWSE_LICENSE_KEY,
    NEW_ADMIN_URL,
    NEW_ADMIN_REPORTS_URL,
    NEW_ADMIN_BILLING_URL,
} from "../../constants/Constants";
import PublicVariables from "../../constants/PublicVariables";

import storage from "../../services/storage";

import { PORTAL_NAME_CHANGE } from "../../reducers/portalname-reducer";
import { getLanguage } from '../../actions/languageAction';
import { getJourneyList } from "../../actions/onLoadFunctionAction";

import { getDomainURL, inItBeamer, isSelfSignupPath, updatePortalName, createLDUserObj, setLDFlags, setPageTitle } from "../../shared/utility";
import { Localize } from "./Localize";
import history from "../../history";
import Languages from "./Languages";

import { PopupModal } from "../UI/Modal/Modal";
import { useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import CreateSessionModal from "../Sessions/CreateSessionModal/CreateSessionModal";
import NotificationBanner from "./NotificationBanner";
import { handleIdleTimeLogic } from "../../shared/cookieHandler";
import UnSupportedBrowser from "./UnSupportedBrowser";

const BeforeAuthHeader = (props) => {
    const [isSubdomain, setIsSubdomain] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [logo, setLogo] = useState(false);
    const [portalName, setPortalName] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(LocaliseLanguageArray.find(lang => lang.id === DefaultLanguage));
    const location = useLocation();
    const ldFlags = useFlags();
    const cobrowseInstance = useRef(false);

    useEffect(() => {
        if (ldFlags) {
            PublicVariables.ldFlags = ldFlags;
            storage.set(LOCAL_STORAGE_KEYS.LDFLags, ldFlags);
        }
    }, [ldFlags]);

    useEffect(() => {
        if (ldFlags?.cbForBlitzzPortal && !cobrowseInstance.current) {
            let st = document.createElement("script");
            st.src = COBROWSE_SDK_URL;
            st.async = true;
            let tx = document.getElementsByTagName("script")[0];
            tx.parentNode.insertBefore(st, tx);

            st.onload = function () {
                if (!cobrowseInstance.current) {
                    try {
                        let blitzz = new BlitzzCobrowse({});
                        blitzz.initialize({
                            license: COBROWSE_LICENSE_KEY,
                        });
                        cobrowseInstance.current = true;
                    } catch (e) {
                        console.info("Failed to initialize BlitzzCobrowse:", e);
                    }
                }
            };

            st.onerror = function () {
                console.info("Failed to load BlitzzCobrowse script.");
            };
        }
    }, [ldFlags?.cbForBlitzzPortal]);

    useEffect(() => {
        setIsSubdomain((getDomainURL() === null || getDomainURL() === "") && !isSelfSignupPath());
        const checkSelfSignupPath = history.location.pathname !== RouteName.CONFIRMATION &&
            history.location.pathname !== RouteName.USER_INFO &&
            history.location.pathname !== RouteName.COMPANY_INFO &&
            history.location.pathname !== RouteName.INVITES;
        setShowHeader(checkSelfSignupPath);
    }, [location.pathname]);



    useEffect(() => {
        setTimeout(() => {
            setLogo(PublicVariables.companyLOGO ? process.env.REACT_APP_COM_URL + PublicVariables.companyLOGO : false);
            setPortalValue();
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPortalName(props.portal.portalname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.portal.portalname]);

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setSelectedLanguage(curLang);
        const languageFromList = PublicVariables.companyPortalNameList ? PublicVariables.companyPortalNameList.find(language => language.languageCode === curLang.id) : false;
        if (languageFromList) {
            updatePortalName(languageFromList.agentPortalName)(props.portalNameChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    const setPortalValue = () => {
        console.log(PublicVariables.companyPortalNameList);
        if (PublicVariables.companyPortalName) {
            updatePortalName(PublicVariables.companyPortalName)(props.portalNameChange);
        }
        else if (PublicVariables.companyPortalNameList && PublicVariables.companyPortalNameList.length > 0) {
            updatePortalName(PublicVariables.companyPortalNameList.find(language => language.languageCode === selectedLanguage.id).agentPortalName)(props.portalNameChange);
        }
        else {
            let toolname = 'Blitzz';//'';//Setting Blitzz as default as of now
            if (!PublicVariables.customDomain && storage.get(LOCAL_STORAGE_KEYS.TOOL_NAME)) {
                toolname = storage.get(LOCAL_STORAGE_KEYS.TOOL_NAME);
            }
            updatePortalName(toolname)(props.portalNameChange);
        }
    }


    return (
        <>
            {showHeader &&
                <div className="login-header">
                    <div className="login-header-logo-box">
                        {!isSubdomain && !PublicVariables.verifiedAccount && logo &&
                            <>
                                <div className="login-header-logo">
                                    <img className="login-header-logo-img" src={logo} alt="logo" data-testid="beforeAuthHeaderLogo" />
                                </div>
                                {/* data-testid will be used for unit test */}
                                <div className="login-header-portal-name" data-testid="headerportalname" id="headerportalname">{portalName}</div>
                            </>
                        }
                    </div>
                    <div className="login-header-right">
                        <Languages
                            isAuthorised={false}
                            selectedlanguage={selectedLanguage}
                        />
                    </div>
                </div>
            }
            <UnSupportedBrowser />
        </>
    );
}
const mapStateToPropsBeforeAuth = (state) => {
    return {
        language: state.language,
        portal: state.portal,
    }
}
const mapDispatchToPropsBeforeAuth = (dispatch) => {
    return {
        portalNameChange: (portalName) => dispatch({
            type: PORTAL_NAME_CHANGE,
            payload: portalName,
        }),
        getLanguage
    }
}

BeforeAuthHeader.propTypes = {
    language: PropTypes.object,
    portal: PropTypes.object,
    portalNameChange: PropTypes.func,
}

export const HeaderBeforeAuth = connect(mapStateToPropsBeforeAuth, mapDispatchToPropsBeforeAuth)(BeforeAuthHeader);


const AfterAuthHeader = (props) => {
    const [logo, setLogo] = useState(null);
    const [profilePic, setProfilePic] = useState(null);
    const [portalName, setPortalName] = useState('');
    const [selectedlanguage, setSelectedlanguage] = useState(LocaliseLanguageArray.find(lang => lang.id === DefaultLanguage));
    const [localisedValues, setLocalisedValues] = useState({});
    const [showInviteGuestModal, setShowInviteGuestModal] = useState(false);
    const [configData, setConfigData] = useState({})
    const [showJourneyEditor, setShowJourneyEditor] = useState(false);
    const ldClient = useLDClient();
    const ldFlags = useFlags();
    const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
    const localConfigData = storage.get(LOCAL_STORAGE_KEYS_ADMIN.CONFIG_DATA);
    const cobrowseInstance = useRef(false);
    const location = useLocation();

    const showInviteGuestButton = location.pathname === RouteName.CREATE_SESSION && PublicVariables.IsBuddy;
    const showNewSessionButton = location.pathname === RouteName.SEND_CODE;
    const showSessionHistoryButton = !location.pathname.includes('session') && !location.pathname.includes('details');
    const domainConfiguration = useMemo(() => { return storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION) }, []);
    useEffect(() => {
        if (ldFlags) {
            PublicVariables.ldFlags = ldFlags;
            storage.set(LOCAL_STORAGE_KEYS.LDFLags, ldFlags);
            if (!props.skipTitle) setPageTitle(props.title);
            storage.set(LOCAL_STORAGE_KEYS.IDEAL_LOGOUT_FLAG, ldFlags['idleLogout'])
            if (PublicVariables.ldFlags?.getbeamer) {
                inItBeamer();
            }
        }
    }, [ldFlags]);

    useEffect(() => {
        if (ldFlags?.cbForBlitzzPortal && !cobrowseInstance.current) {
            const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);          
            let st = document.createElement("script");
            st.src = COBROWSE_SDK_URL;
            st.async = true;
            let tx = document.getElementsByTagName("script")[0];
            tx.parentNode.insertBefore(st, tx);

            st.onload = function () {
                if (!cobrowseInstance.current) {
                    try {
                        let blitzz = new BlitzzCobrowse({});
                        let referenceName = userData.notificationEmail;
                        if (referenceName == null || referenceName == "" || referenceName == undefined) {
                            referenceName = userData.firstName + " " + userData.lastName;
                        }
                        blitzz.initialize({
                            license: COBROWSE_LICENSE_KEY,
                            reference: referenceName
                        });
                        cobrowseInstance.current = true;
                    } catch (e) {
                        console.info("Failed to initialize BlitzzCobrowse:", e);
                    }
                }
            };

            st.onerror = function () {
                console.info("Failed to load BlitzzCobrowse script.");
            };
        }
    }, [ldFlags?.cbForBlitzzPortal]);
    

    useEffect(() => {
        // IF user tried to access the private page before login
        if(!storage.get(LOCAL_STORAGE_KEYS.USER_DATA)){
            history.push(RouteName.LOGIN);
        }
    })

    useEffect(() => {
        // for universal flow
        if (storage.has(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION) && storage.has(LOCAL_STORAGE_KEYS.USER_DATA)) {
            const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
            const domainConfigurationData = storage.get(LOCAL_STORAGE_KEYS.DOMAIN_CONFIGURATION);
            const obj = {
                domainId: userData?.domainId,
                domainName: userData?.domainName,
                domainEmail: domainConfigurationData.configurations?.domainemail,
                releaseVersion: domainConfigurationData.configurations?.domainreleaseversion,
                edition: domainConfigurationData.configurations?.edition,
                subscriptiontype: domainConfigurationData.billingData?.subscriptiontype,
                IsTrial: PublicVariables.istrial,
                subscriptionstatus: domainConfigurationData.billingData?.subscriptionstatus,
                partnername: domainConfigurationData.billingData?.partnername
            }
            let ldUser = createLDUserObj(obj);
            console.log("New user's flags available in header.js");
            if (ldClient) {
                setLDFlags(ldClient, ldUser);
            }
        }
        if (PublicVariables.domainDataForLD) {
            const obj = {
                domainId: PublicVariables.domainDataForLD?.domainId,
                domainName: PublicVariables.domainDataForLD?.domainName,
                domainEmail: PublicVariables.domainDataForLD?.domainEmail,
                releaseVersion: PublicVariables.domainDataForLD?.releaseVersion,
                edition: PublicVariables.domainDataForLD?.edition,
                subscriptiontype: PublicVariables.domainDataForLD?.billingData?.subscriptiontype,
                IsTrial: PublicVariables.domainDataForLD?.isTrial,
                subscriptionstatus: PublicVariables.domainDataForLD?.billingData?.subscriptionstatus,
                partnername: PublicVariables.domainDataForLD?.billingData?.partnername
            }
            let ldUser = createLDUserObj(obj);
            console.log("New user's flags available in header.js");
            if (ldClient) {
                setLDFlags(ldClient, ldUser);
            }
        }
        setTimeout(() => {
            const configData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
            const domainLogo = PublicVariables.companyLOGO || domainConfiguration.domainLogo;
            console.log(process.env.REACT_APP_COM_URL);
            console.log(PublicVariables.companyLOGO);
            setLogo(process.env.REACT_APP_COM_URL + domainLogo);
            // need to add after domain URL null
            // setLogo(process.env.REACT_APP_COM_URL + (PublicVariables.companyLOGO ? PublicVariables.companyLOGO : configData.COMPANYDETAIL.LOGOIMAGE));
            const profilPic = localConfigData?.PROFILEPIC || configData?.profileImage
            setProfilePic(process.env.REACT_APP_PROFILE_URL + profilPic);

            setConfigData(configData)
            // setPortalValue();

            // Updating Favicon
            const faviconLogo = PublicVariables.companyFAVICON ?? domainConfiguration?.faviconImage
            const favicon = document.getElementById("favicon");
            if (faviconLogo) {
                favicon.setAttribute("href", process.env.REACT_APP_FAVICON_URL + faviconLogo);
            }
        }, 300);

        window.addEventListener('click', handleIdleTimeLogic)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const journeyListData = props.onLoadData?.getJourneyListData;
        if (journeyListData?.length > 0) {
            setShowJourneyEditor(true);
        }
    }, [props.onLoadData?.getJourneyListData]);

    useEffect(() => {
        setPortalName(props.portal.portalname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.portal.portalname]);

    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setSelectedlanguage(curLang);
        setLocalisedValues(Localize[curLang.id]);
        const languageFromList = PublicVariables.companyPortalNameList ? PublicVariables.companyPortalNameList.find(language => language.languageCode === curLang.id) : false;
        if (languageFromList) {
            updatePortalName(languageFromList.agentPortalName)(props.portalNameChange);
        }
        else {
            updatePortalName(props.language.selectedlanguage?.agentPortalName)(props.portalNameChange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(() => {
        const interval = setInterval(() => {
            const userData = storage.get(LOCAL_STORAGE_KEYS.USER_DATA);
            if (PublicVariables?.configurations?.journeyeditor === 1) {
                clearInterval(interval);
                if (userData.roleCode !== USER_ROLE_CODE.superAdmin && userData.roleCode !== USER_ROLE_CODE.administrator) {
                    fetchJourneyList();
                } else {
                    setShowJourneyEditor(true);
                }
            }
        }, 1000)
        return () => clearInterval(interval);
    }, []);

    const setPortalValue = () => {
        if (PublicVariables.companyPortalName) {
            updatePortalName(PublicVariables.companyPortalName)(props.portalNameChange);
        }
        else if (PublicVariables.companyPortalNameList && PublicVariables.companyPortalNameList.length > 0) {
            updatePortalName(PublicVariables.companyPortalNameList.find(language => language.languageCode === selectedlanguage.id).agentPortalName)(props.portalNameChange);
        }
        else {
            let toolname = 'Blitzz';//'';//Setting Blitzz as default as of now
            if (!PublicVariables.customDomain && storage.get(LOCAL_STORAGE_KEYS.TOOL_NAME)) {
                toolname = storage.get(LOCAL_STORAGE_KEYS.TOOL_NAME);
            }
            updatePortalName(toolname)(props.portalNameChange);
        }
    }

    const handleOnLogOutClick = () => {
        history.push(RouteName.LOGOUT)
    }

    const closeInviteGuestModal = () => {
        setShowInviteGuestModal(false);
    }

    const newSessionClickHandler = () => {
        storage.remove(LOCAL_STORAGE_KEYS.DATA_FOR_SESSION);
        history.push(RouteName.CREATE_SESSION);
    }

    const sessionHistoryClickHandler = () => {
        history.push(RouteName.SESSIONS);
    }
    //#region Invite_Guest
    const inviteGuestClickHandler = () => {
        setShowInviteGuestModal(true);
    }

    /**
     * The fetchJourneyList function calls the getJourneyList function from the props object.
    */
    const fetchJourneyList = () => {
        props.getJourneyList();
    }
    //#endregion

    const [statusErrorModal, setStatusErrorModal] = useState(false);
    const [isExpiredStatusCode, setIsExpiredStatusCode] = useState(false);

    const closeStatusErrorModal = () => {
        setStatusErrorModal(false);
    }

    const logoutClickHandler = () => {
        closeStatusErrorModal();
        history.replace(RouteName.LOGOUT);
    }

    const gotoContactPage = () => {
        window.open(ContactURL, "_blank");
    }

    const goToBillingPage = () => {
        window.location.href = window.location.origin + BillingURLPath;
    }

    const statusErrorModalHeaderContent = (
        <>
            {isExpiredStatusCode &&
                <h5 className="modal-title">
                    {PublicVariables.title === PausedKey ? localisedValues['your_subscription_has_been_paused'] : localisedValues['subscription_ended_modal_title']}
                </h5>
            }
            {!isExpiredStatusCode &&
                <h5 className="modal-title modal-title-with-icon">
                    <span className="modal-title-icon icon-warning"></span>
                    <span className="modal-title-text">{PublicVariables.title}</span>
                </h5>
            }
        </>
    );

    const statusErrorModalBodyContent = (
        <div className="cms-modal-text">
            {isExpiredStatusCode &&
                <>
                    {PublicVariables.title === PausedKey &&
                        <p>{localisedValues['subscription_paused_description']}</p>
                    }
                    {PublicVariables.title !== PausedKey &&
                        <>
                            <h3>{localisedValues['subscription_ended_modal_subtitle']}</h3>
                            <p>{localisedValues['subscription_ended_modal_msg1']}</p>
                            <p>{localisedValues['subscription_ended_modal_msg2']}</p>
                        </>
                    }
                </>
            }
            {!isExpiredStatusCode &&
                <p>{PublicVariables.message}</p>
            }
        </div>
    );

    const statusErrorModalFooterContent = (
        <>
            {isExpiredStatusCode &&
                <div className="button">
                    <button type="button" className="btn btn-outline-danger btn-with-icon font-weight-500" onClick={logoutClickHandler}>{localisedValues['logout']}</button>
                </div>
            }
            {!isExpiredStatusCode &&
                <div className="button">
                    <button type="button" className="btn btn-primary font-weight-500" onClick={logoutClickHandler}>{localisedValues['okay_cap']}</button>
                </div>
            }
            {isExpiredStatusCode && ((userData?.roleCode != USER_ROLE_CODE.superAdmin && userData?.roleCode != USER_ROLE_CODE.billing) && ldFlags.billing) && PublicVariables.title !== PausedKey &&
                <div className="button">
                    <button type="button" className="btn btn-primary font-weight-500" onClick={gotoContactPage}>{localisedValues['contact_us']}</button>
                </div>
            }
            {((isExpiredStatusCode && !ldFlags.billing) || PublicVariables.title === PausedKey) &&
                <div className="button">
                    <button type="button" className="btn btn-primary font-weight-500" onClick={gotoContactPage}>{localisedValues['contact_us']}</button>
                </div>
            }
            {isExpiredStatusCode && (userData?.roleCode == USER_ROLE_CODE.superAdmin || userData?.roleCode == USER_ROLE_CODE.billing) && ldFlags.billing && PublicVariables.title !== PausedKey &&
                <div className="button">
                    <button type="button" className="btn btn-primary font-weight-500" onClick={goToBillingPage}>{localisedValues['go_to_billing']}</button>
                </div>
            }
        </>
    );

    const openCloseStatusModal = (value) => {
        setStatusErrorModal(value);
        setIsExpiredStatusCode(PublicVariables.statusCode == errorStatusCode.trailPeriodExpired)
    }

    const redirectToAdmin = (type) => {
        const base_url = window.location.origin;
        const switchToNewAdmin = storage.get(LOCAL_STORAGE_KEYS.SWITCH_TO_NEW_ADMIN)
        if (switchToNewAdmin === null || switchToNewAdmin === undefined || switchToNewAdmin === "") {
            storage.set(LOCAL_STORAGE_KEYS.SWITCH_TO_NEW_ADMIN, true);
        }
        const isNewAdmin = storage.get(LOCAL_STORAGE_KEYS.SWITCH_TO_NEW_ADMIN) === true;
        
        const urls = {
            reports: isNewAdmin ? NEW_ADMIN_REPORTS_URL : REPORTS_URL,
            billing: isNewAdmin ? NEW_ADMIN_BILLING_URL : BILLING_URL,
            profile: isNewAdmin ? NEW_ADMIN_URL : SETTINGS_URL
        };
        
        const url = urls[type] || urls.profile;
        window.open(`${base_url}${url}`);
    };

    return (
        <>
            {(history.location.pathname !== RouteName.INVITES) && <div className={clsx("top-header", (history.location.pathname === RouteName.CREATE_SESSION) && "invite-contact-header", (history.location.pathname === RouteName.SEND_CODE) && "share-invite")}>
                <div className="header-logo-box">
                    {logo &&
                        <div className="header-logo">
                            <img className="header-logo-img" src={logo} alt="logo" data-testid="afterAuthHeaderLogo" />
                        </div>
                    }
                    {portalName && <div className="header-portal-name" id="headerportalname" data-testid="afterAuthHeaderPortalName">{portalName}</div>}
                </div>
                <div className="top-header-right">
                    {PublicVariables?.istrial &&
                        <div className={clsx("header-trial-plan", ((userData?.roleCode == USER_ROLE_CODE.superAdmin || userData?.roleCode == USER_ROLE_CODE.billing) && PublicVariables.ldFlags.billing) ? '' : 'billing-false')}>
                            <div className="header-trial-name">
                                {domainConfiguration?.configurations?.edition}&nbsp;<span>{localisedValues['trial_key']}</span>:
                            </div>
                            <div className="header-trial-value">
                                {PublicVariables.expireDay > 0 && <span className={clsx((userData?.roleCode == USER_ROLE_CODE.superAdmin || userData?.roleCode == USER_ROLE_CODE.billing) && PublicVariables.ldFlags.billing && 'day-left-hide')} >&nbsp;{PublicVariables.expireDay}&nbsp;<span>{localisedValues[PublicVariables.expireDay > 1 ? 'days_left' : 'day_left']}</span></span>}
                                {PublicVariables.expireDay === 0 && <span className={clsx((userData?.roleCode == USER_ROLE_CODE.superAdmin || userData?.roleCode == USER_ROLE_CODE.billing) && PublicVariables.ldFlags.billing && 'day-left-hide')}>&nbsp;<span>{localisedValues['ends_today']}</span></span>}
                                {((userData?.roleCode == USER_ROLE_CODE.superAdmin || userData?.roleCode == USER_ROLE_CODE.billing) && PublicVariables.ldFlags.billing) &&
                                    <div className="plan-upgrade-btn">
                                        <a className="upgrade-btn" href={window.location.origin + BillingURLPath} rel="noreferrer" target="_blank">{localisedValues['upgrade_key']}</a>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {PublicVariables.ldFlags?.getbeamer &&
                        <div className="header-whatsnew beamerButton">
                            <button className="btn" data-testid="whatsNewButton">
                                <span className="btn-icon icon-lightbulb"></span>
                                <span className="btn-text">{localisedValues['whats_new']}</span>
                            </button>
                        </div>
                    }
                    {/* do not sow below button */}
                    <button className="btn d-none" data-testid="status-error-modal" id="status-error-modal" onClick={() => openCloseStatusModal(true)}>
                        Show Modal
                    </button>
                    {(showInviteGuestButton || showNewSessionButton || showSessionHistoryButton) && <div className="top-header-button button-inline d-flex">
                        {showInviteGuestButton &&
                            <div className="button">
                                <button className="btn btn-primary btn-with-icon" onClick={inviteGuestClickHandler}>
                                    <span className="btn-icon icon-invite-guest"></span>
                                    <span className="btn-text">{localisedValues['invite_guest']}</span>
                                </button>
                            </div>
                        }
                        {showNewSessionButton &&
                            <div className="button">
                                <button className="btn btn-primary btn-with-icon" onClick={newSessionClickHandler}>
                                    <span className="btn-icon icon-create-session"></span>
                                    <span className="btn-text">{localisedValues['new_session']}</span>
                                </button>
                            </div>
                        }
                        {showSessionHistoryButton &&
                            <div className="button">
                                <button className="btn btn-primary btn-with-icon" onClick={sessionHistoryClickHandler}>
                                    <span className="btn-icon icon-session-history"></span>
                                    <span className="btn-text">{localisedValues['session_history']}</span>
                                </button>
                            </div>
                        }
                    </div>}
                    <div className="header-profile-dropdown">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="profile-dropdown" data-testid="profileDropdownButton"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="hdr-profile-text">
                                    <span className="hdr-profile-hi">{localisedValues['hi']}</span> <span className="hdr-profile-name">{localConfigData?.FNAME ||  userData?.firstName}</span>
                                </div>
                                <div className="hdr-profile-icon">
                                    <img className="hdr-profile-icon-img" src={profilePic} alt="User" />
                                </div>
                                <div className="for-icon icon-down-triangle-round"></div>
                            </button>
                            <div className="dropdown-menu right" aria-labelledby="profile-dropdown" data-testid="profileDropdown">
                                <div className="dropdown-menu-in">
                                    <div className="header-profile-hdr">
                                        <div className="hdr-profile-icon-out">
                                            <div className="hdr-profile-icon">
                                                <img className="hdr-profile-icon-img" src={profilePic} alt="User" />
                                            </div>
                                        </div>
                                        <div className="hdr-profile-text">
                                            <div className="user-name">
                                                {localConfigData?.FNAME ||  userData?.firstName}&nbsp;{localConfigData?.LNAME || userData?.lastName}
                                            </div>
                                            <div className="user-info">
                                                {userData?.domainName} - {userData?.roleDisplayName}
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="header-profile-ul custom-scroll-div-thin">
                                        <li className="header-profile-li">
                                            <Languages
                                                isAuthorised={true}
                                                selectedlanguage={selectedlanguage}
                                            />
                                        </li>
                                        <li className="header-profile-li">
                                            <a className="dropdown-item dropdown-item-out pointer" href={void(0)} target="_blank" rel="noreferrer" data-testid="settingsButton" onClick={() => redirectToAdmin("profile")}>
                                                <div className="dropdown-item-box">
                                                    <span className="dropdown-item-icon icon-settings"></span>
                                                    <span className="dropdown-item-text">{localisedValues['setttings']}</span>
                                                    <span className="new-tab-icon icon-newtab"></span>
                                                </div>
                                            </a>
                                        </li>
                                        {(configData?.roleCode === USER_ROLE_CODE.administrator || configData?.roleCode === USER_ROLE_CODE.superAdmin) && PublicVariables?.configurations?.journeyeditor === 1 && showJourneyEditor && <li className="header-profile-li">
                                            <a className="dropdown-item dropdown-item-out" href={window.location.origin + JOURNEY_URL} target="_blank" rel="noreferrer">
                                                <div className="beta-label">
                                                    <span className="text">BETA</span>
                                                </div>
                                                <div className="dropdown-item-box">
                                                    <span className="dropdown-item-icon icon-journey"></span>
                                                    <span className="dropdown-item-text">{localisedValues['journey_editor']}</span>
                                                    <span className="new-tab-icon icon-newtab"></span>
                                                </div>
                                            </a>
                                        </li>}
                                        {PublicVariables?.configurations?.cobrowse === '1' && <li className="header-profile-li">
                                            <a className="dropdown-item dropdown-item-out" href={window.location.origin + COBROWSE_URL} target="_blank" rel="noreferrer">
                                                <div className="dropdown-item-box">
                                                    <span className="dropdown-item-icon icon-cobrowse"></span>
                                                    <span className="dropdown-item-text">{localisedValues['cobrowse']}</span>
                                                    <span className="new-tab-icon icon-newtab"></span>
                                                </div>
                                            </a>
                                        </li>}
                                        {(configData?.roleCode !== USER_ROLE_CODE.internal && configData?.roleCode !== USER_ROLE_CODE.external) && PublicVariables?.ldFlags?.reportsFeature && <li className="header-profile-li">
                                            <a className="dropdown-item dropdown-item-out pointer" href={void(0)} target="_blank" rel="noreferrer" data-testid="reportsButton" onClick={() => redirectToAdmin("reports")}>
                                                <div className="dropdown-item-box">
                                                    <span className="dropdown-item-icon icon-reports"></span>
                                                    <span className="dropdown-item-text">{localisedValues['reports']}</span>
                                                    <span className="new-tab-icon icon-newtab"></span>
                                                </div>
                                            </a>
                                        </li>}
                                        {(configData?.roleCode === USER_ROLE_CODE.superAdmin || configData?.roleCode === USER_ROLE_CODE.billing) && PublicVariables?.ldFlags?.billing && <li className="header-profile-li">
                                            <a className="dropdown-item dropdown-item-out pointer" href={void(0)} target="_blank" rel="noreferrer" data-testid="billingButton" onClick={() => redirectToAdmin("billing")}>
                                                <div className="dropdown-item-box">
                                                    <span className="dropdown-item-icon icon-billing"></span>
                                                    <span className="dropdown-item-text">{localisedValues['BL-Role']}</span>
                                                    <span className="new-tab-icon icon-newtab"></span>
                                                </div>
                                            </a>
                                        </li>}

                                        {/* <li className="header-profile-li">
                                                <a className="dropdown-item" href="/">
                                                    <div className="dropdown-item-box">
                                                        <span className="dropdown-item-icon icon-dark-fill"></span>
                                                        <span className="dropdown-item-text">Dark</span>
                                                    </div>
                                                </a>
                                            </li> */}

                                        <li className="header-profile-li">
                                            <div className="header-signout-btn" data-testid="signOutButton">
                                                <button className="btn dropdown-item dropdown-item-out" onClick={handleOnLogOutClick}>
                                                    <div className="dropdown-item-box">
                                                        <span className="dropdown-item-icon icon-signout"></span>
                                                        <span className="dropdown-item-text">{localisedValues['recnt_used_device_sign_out']}</span>
                                                    </div>                                                    
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
            {showInviteGuestModal && <CreateSessionModal closeModal={closeInviteGuestModal} />}
            {/* need to handle Browser notifications not supported model for Journey incoming call notification */}
            <NotificationBanner />
            <audio id="notificationSignal" preload="auto">
                <source src={notificationAudioOgg} type="audio/ogg"></source>
                <source src={notificationAudioMp3} type="audio/mpeg"></source>
            </audio>
            {statusErrorModal &&
                <PopupModal
                    closeModal={closeStatusErrorModal}
                    modalClass="modal-medium alert-cms-modal"
                    dialogClass="modal-dialog-centered"
                    closeButton={false}
                    headerContent={statusErrorModalHeaderContent}
                    bodyContent={statusErrorModalBodyContent}
                    footerContent={statusErrorModalFooterContent}
                    backdrop='static'
                />
            }
            <UnSupportedBrowser />
        </>
    );
}
const mapStateToPropsAfterAuth = (state) => {
    return {
        language: state.language,
        portal: state.portal,
        onLoadData: state.onLoadData
    }
}

const mapDispatchToPropsAfterAuth = (dispatch) => {
    return {
        portalNameChange: (portalName) => dispatch({
            type: PORTAL_NAME_CHANGE,
            payload: portalName,
        }),
        getLanguage,
        getJourneyList: () => dispatch(getJourneyList())
    }
}

AfterAuthHeader.propTypes = {
    language: PropTypes.object,
    portal: PropTypes.object,
    portalNameChange: PropTypes.func,
    skipTitle: PropTypes.bool,
    title: PropTypes.string,   
    onLoadData: PropTypes.object,
    getJourneyList: PropTypes.func,
}

export const HeaderAfterAuth = connect(mapStateToPropsAfterAuth, mapDispatchToPropsAfterAuth)(AfterAuthHeader);
import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field } from "formik";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import '../../assets/css/country-flag.scss';
import { Localize } from "./Localize";
import SelfSignup from "./SelfSignup";
import { FormikInputWithIcon } from "../UI/TextInput/FormikInputWithIcon";
import PublicVariables from "../../constants/PublicVariables";
import history from "../../history";
import { RouteName, SUBSCRIPTIONTYPE, TERMS_AND_CONDITION_URL } from "../../constants/Constants";
import { SubmitButton } from "../UI/Button/Button";
import { CheckCompanyForSelfSignup, CreateCompanyForSelfSignup } from "../../actions/selfSignupAction";
import CustomToast from "../UI/customToast";
import CountryStateFile from './CountryState.txt';
import { CountryStateDropDown } from "./CountryStateDropDown";

let initValue = {
    companyName: '',
    count:'',
    userCompany:'',
    countryNameSearch:'',
    stateNameSearch: '',
}

const Companyinfo = (props) => {
    const [localisedValues, setLocalisedValues] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedCountryName, setSelectedCountryName] = useState('Select country'); // set selected country name
    const [selectedStateName, setSelectedStateName] = useState('Select state'); // set selected state name
    const [selectedCountryCode, setSelectedCountryCode] = useState(''); // set selected code
    // const [selectedCountryState, setSelectedCountryState] = useState(''); // set selected country
    const [allCountryData, setAllCountryData] = useState([]); // set selected all countries data
    const [statesList, setStatesList] = useState([]); // set selected all countries data
    const [countrySearchValue, setCountrySearchValue] = useState('');
    const [stateSearchValue, setStateSearchValue] = useState('');
    const [isCompanyCreated, setIsCompanyCreated] = useState(false)
    
    const fetchCountryStateData = useCallback(() => {
        fetch(CountryStateFile)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setAllCountryData(data)  // set all country data
            })
            .catch((error) => {
                console.error('Error fetching the text file:', error);
            });
    }, []);
    
    useEffect(() => {
        const curLang = props.language.selectedlanguage;
        setLocalisedValues(Localize[curLang.id]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.language.selectedlanguage]);

    useEffect(()=>{
        if(!PublicVariables.trialemail || (PublicVariables.trialemail && !PublicVariables.trialemail.email)){
            history.replace(RouteName.GET_STARTED);
        }
        fetchCountryStateData(); // fetch country state data from the text file
    },[])

    useEffect(()=>{
        if(props.selfSignup.checkCompanyData){
            console.log(props.selfSignup.checkCompanyData);
            if(props.selfSignup.checkCompanyData.Errorcode === 1){
                CustomToast.warn(localisedValues['subdomain_not_available']);        
            }
        }
    },[props.selfSignup.checkCompanyData])

    useEffect(()=>{
        setIsSubmitting(props.selfSignup.createCompanyLoading)
        if(props.selfSignup.createCompanyData){
            setIsCompanyCreated(true)
            // storage.set( .FROM_STATE, "get-started");
        }
    },[props.selfSignup.createCompanyData, props.selfSignup.createCompanyLoading])

    function getSubDomainOnCompanyName(_companyName){
        let _subDomain = _companyName.toLowerCase().split(" ").join("");
        _subDomain = _subDomain.replace(/[^0-9a-z-]/g,'');
        _subDomain = _subDomain.replace(/(^[-]+|[-]+$)/g,'');
        return _subDomain;
    }

    const onCompanyNameChanged = (e,setFieldValue) =>{
        console.log(e.target.value);
        const userCompany = getSubDomainOnCompanyName(e.target.value);     
        setFieldValue("userCompany", userCompany);  
        setFieldValue("companyName", e.target.value);  
        if(userCompany.length > 2){
            const subdomain =  "https://" + userCompany + process.env.REACT_APP_SUBDOMAINURL;
            PublicVariables.trialemail.subdomain = subdomain;
            PublicVariables.trialemail.userCompany = userCompany;
            props.CheckCompanyForSelfSignup({domainURL: subdomain, from:"get-started"});
        }           
    }

    const registerCompany = (obj) => {
        console.log(obj);
        console.log(PublicVariables.trialemail);
        let request = {
            "verificationId": PublicVariables.trialemail.verificationId,
            "domainName": obj.companyName,
            "subscriptionType": (PublicVariables.trialemail.SUBSCRIPTIONTYPE === SUBSCRIPTIONTYPE.CREDITS) ? 2 : 1,
            "billableUsers": (PublicVariables.trialemail.SUBSCRIPTIONTYPE === SUBSCRIPTIONTYPE.USERS) ? obj.count : 0,
            "billableSessions": (PublicVariables.trialemail.SUBSCRIPTIONTYPE === SUBSCRIPTIONTYPE.CREDITS) ? obj.count : 0,
            "emailId": PublicVariables.trialemail.email,
            "firstName": PublicVariables.trialemail.hasuserprofile ?  PublicVariables.trialemail.hasuserprofile.firstName : PublicVariables.trialemail.FNAME,
            "lastName": PublicVariables.trialemail.hasuserprofile ?  PublicVariables.trialemail.hasuserprofile.lastName : PublicVariables.trialemail.LNAME,
            "mobileNo": PublicVariables.trialemail.hasuserprofile ?  PublicVariables.trialemail.hasuserprofile.mobileNo : '',
            "jobRole": PublicVariables.trialemail.hasuserprofile ?  PublicVariables.trialemail.hasuserprofile.jobRole : '',
            "domainUrlPrefix": PublicVariables.trialemail.userCompany,
            "country": selectedCountryName,
            "state": selectedStateName
          }
          if( PublicVariables.trialemail.UPASSWORD){
            request.password = PublicVariables.trialemail.UPASSWORD;
          }
          props.CreateCompanyForSelfSignup(request);
    }

    /* handle country change on button click */
    const handleCountryChanged = (code) => {
        if (code != selectedCountryCode) {
            const states = code ? allCountryData.find(ctr => ctr.CODE == code).STATES : [];
            setStatesList(states)
            // setSelectedCountryState(states)
            setSelectedCountryCode(code);
            const countryName = code ? allCountryData.find(ctr => ctr.CODE == code).NAME : '';
            setSelectedCountryName(countryName)
            setSelectedStateName(localisedValues['select_state_label'])
        }
    };

    /* search country and based on that show country list */
    const filteredCountryBasedOnSearch = allCountryData?.filter((item) => {
        if (countrySearchValue === '') {
            return item;
        } else {
            return item.NAME.toLowerCase().includes(countrySearchValue)
        }
    });

    /* search state and based on that show state list */
    const filteredStateBasedOnSearch = statesList?.filter((item) => {
        if (stateSearchValue === '') {
            return item;
        } else {
            return item.toLowerCase().includes(stateSearchValue)
        }
    });

    const handleSetSelectState = (setFieldValue) => {
        setStateSearchValue('');
        setFieldValue('stateNameSearch', '');
        document.getElementById('stateNameSearchId').value = '';
    }

    const handleSetSelectCountry = (setFieldValue) => {
        setCountrySearchValue('');
        setFieldValue('countryNameSearch', '');
        document.getElementById('countryNameSearchId').value = '';
    }

    return (
        <SelfSignup companyCreated={isCompanyCreated}>
            <div className="signup-in-scroll custom-scroll-div">
                <div className="signup-stepno">
                    {localisedValues['step_2_of_2']}
                </div>
                <div className="login-logo-subtext text-center" data-testid="heading">
                    <h2>{localisedValues['company_info']}</h2>
                </div>
                <Formik
                    initialValues={initValue}
                    validationSchema={ 
                        Yup.object({
                            companyName: Yup
                                .string()
                                .required(localisedValues['company_name_error'])
                                .max(30, localisedValues['crt_acnt_limit_character_msg']),
                            count: Yup
                                .number()
                                .integer(localisedValues["invalid_number_of_employees"])
                                .required('')
                                .positive()
                                .moreThan(0, (PublicVariables.trialemail && (PublicVariables.trialemail.SUBSCRIPTIONTYPE === SUBSCRIPTIONTYPE.CREDITS)) ? localisedValues["invalid_no_of_inspections"] : localisedValues["invalid_number_of_employees"])
                                .max(1000000, localisedValues['error_limit_exceeded']),
                            userCompany:Yup
                                .string()
                                .required(localisedValues['subdomain_error'])
                                .min(3, localisedValues['domain_length_msg'])
                                .max(30, localisedValues['crt_acnt_limit_character_msg'])
                                .matches('^[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$',localisedValues['not_a_valid_subdomain']),
                            countryNameSearch:Yup
                                .string(),
                            stateNameSearch:Yup
                                .string()
                    })}

                    onSubmit={(values, { _setSubmitting }) => {
                        setTimeout(() => {
                            registerCompany(values);
                        }, 400);
                    }}
                >
                    {formik => (
                            <Form>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <FormikInputWithIcon
                                            id="companyName"
                                            type="text"
                                            className="height-46 focus-font-bold"
                                            placeholder={localisedValues["company_name"]}
                                            touched={formik.touched.companyName}
                                            error={formik.errors.companyName}
                                            onChange={e => onCompanyNameChanged(e, formik.setFieldValue)}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <FormikInputWithIcon
                                            id="count"
                                            type="number"
                                            className="height-46 focus-font-bold"
                                            placeholder={(PublicVariables.trialemail && (PublicVariables.trialemail.SUBSCRIPTIONTYPE === SUBSCRIPTIONTYPE.CREDITS)) ? localisedValues["no_of_inspections"] : localisedValues["number_of_employees"]}
                                            touched={formik.touched.count}
                                            error={formik.errors.count}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>                            

                                <div className="form-group">
                                    <label className="form-label">Your Blitzz subdomain URL</label>
                                    <div className="form-group-in subdomain-form-group">
                                        <Field
                                            id="userCompany"
                                            name="userCompany"
                                            type="text"
                                            className="form-control form-control-grey height-46 focus-font-bold"
                                            placeholder={localisedValues["subdomain"]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            touched={formik.touched.userCompany}
                                            error={formik.errors.userCompany}
                                        />
                                        <div className="subdomain-mark">{process.env.REACT_APP_SUBDOMAINURL}</div>
                                    </div>	
                                    {formik.touched.userCompany && formik.errors.userCompany && <div className="validation-error">{formik.errors.userCompany}</div>}					
                                </div>
                                <CountryStateDropDown 
                                    selectedCountryCode={selectedCountryCode}
                                    selectedCountryName={selectedCountryName}
                                    noCountrySelectedText={localisedValues['select_country_label']}
                                    formik={formik}
                                    handleSetSelectCountry={handleSetSelectCountry}
                                    setCountrySearchValue={setCountrySearchValue}
                                    countrySearchValue={countrySearchValue}
                                    filteredCountryBasedOnSearch={filteredCountryBasedOnSearch}
                                    noSearchFound={localisedValues['no_search_result_found_label']}
                                    handleCountryChanged={handleCountryChanged}
                                    handleSetSelectState={handleSetSelectState}
                                    selectedStateName={selectedStateName}
                                    setStateSearchValue={setStateSearchValue}
                                    stateSearchValue={stateSearchValue}
                                    noStateSelectedText={localisedValues['select_state_label']}
                                    filteredStateBasedOnSearch={filteredStateBasedOnSearch}
                                    setSelectedStateName={setSelectedStateName}
                                />
                                <div className="subdomain-seperator"></div>
                                <div className="button-inline text-center padding-top-15 d-flex justify-content-center">
                                    <div className="button button-full-width">
                                        <SubmitButton 
                                            className="btn btn-primary font-weight-500 btn-with-icon"
                                            id="create_my_account"
                                            data-testid="createAccountButton"
                                            name={localisedValues['create_my_account']} 
                                            isSubmitting={isSubmitting} 
                                            disable={isSubmitting || !formik.isValid || !selectedCountryCode || (selectedStateName === localisedValues['select_state_label'])} 
                                            showLoader={true} 
                                        />
                                    </div>
                                </div>
                            </Form>
                    )}
                </Formik>
                <div className="login-bottom-text padding-top-15">
                    <div className="have-an-account text-center">
                        <span>{localisedValues["create_my_account_text1"]}</span><br/>
                        <a href={TERMS_AND_CONDITION_URL} target="_blank" rel="noreferrer" className="text-dec-none">{localisedValues["new_terms_and_conditions"]}</a>
                    </div>
                </div>
            </div> 
        </SelfSignup>
    )
}

Companyinfo.propTypes = {
    CheckCompanyForSelfSignup: PropTypes.func,
    CreateCompanyForSelfSignup: PropTypes.func,
    language: PropTypes.object,
    selfSignup: PropTypes.object,

}

const mapStateToPropsCompanyinfo = (state) => {
    return {
        language: state.language,
        selfSignup: state.selfSignup
    }
}

const mapDispatchToPropsCompanyinfo = (dispatch) =>{
    return {
        CheckCompanyForSelfSignup: (req) => dispatch(CheckCompanyForSelfSignup(req)),
        CreateCompanyForSelfSignup: (req) => dispatch(CreateCompanyForSelfSignup(req)),
    }
}

export default connect(mapStateToPropsCompanyinfo, mapDispatchToPropsCompanyinfo)(Companyinfo);
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { ReactMultiEmail } from "react-multi-email";
import QRcodeImage from "../../assets/images/preview-email-qr.jpg";
import { EMAIL_PATTERN_REGEX,  RouteName } from "../../constants/Constants";
import history from "../../history";
import { Localize } from "./Localize";
import { handleErrorMsgToastVisiblity, openUrlInBrowser,  resetDataForSession } from "../../shared/utility";
import {
  createSessionReset,
  inviteSession,
  inviteSessionReset,
} from "../../actions/sessionListAction";
import CustomToast from "../UI/customToast";
import { PopupModal } from "../UI/Modal/Modal";
import { ButtonWithLoader } from "../UI/Button/Button";

const SendEmail = ({
  id,
  isDefaultInvite,
  sessionDetailData,
  sendUrl,
  agentUrl,
  userData,
  configData,
  isUploadOnlySessionValue,
  emailRef,
  isInviteAPICalledRef,
  isSessionOwner,
  isForSaskTail
}) => {
  const dispatch = useDispatch();
  const { inviteSessionsLoading, inviteSessionsData, inviteSessionsError } =
    useSelector((state) => state.sessionList);
  const { language } = useSelector((state) => state);

  const [emails, setEmails] = useState(emailRef.current);
  const [focused, setFocused] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [emailTemplateheight, setEmailTemplateheight] = useState({});
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [inviteButton, setInviteButton] = useState("");
  const [localisedValues, setLocalisedValues] = useState({});
  const [isInviteAPICalled, setIsInviteAPICalled] = useState(
    isInviteAPICalledRef.current
  );
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(true)

  const emaiRregex = new RegExp(EMAIL_PATTERN_REGEX);
  const emailSentSuccessMessage = localisedValues["email_sent_label"];

  useEffect(()=>{
    setFocused(true);
    if(emails?.length > 0){ // sasktel case 
      onEmailChangeHandler(emails[0]);
    }
  },[]);

  useEffect(() => {
    emailRef.current = emails;

    if(emails.length === 0){
      setIsButtonsDisabled(true)
    }
  }, [emails]);

  useEffect(() => {
    isInviteAPICalledRef.current = isInviteAPICalled;
  }, [isInviteAPICalled]);

  useEffect(() => {
    const curLang = language.selectedlanguage;
    setLocalisedValues(Localize[curLang.id]);
  }, [language.selectedlanguage]);

  useEffect(() => {
    if (inviteSessionsLoading) return;

    if (inviteSessionsData) {
      if (isUploadOnlySessionValue) {
        CustomToast.success(
          `${emailSentSuccessMessage} ${localisedValues["recipients_can_upload_files"]}`
        );
        history.replace(RouteName.SESSIONS);
      } else {
        CustomToast.success(localisedValues["invite_sent_successfully"]);
      }
      const emailInput = document.getElementById("emailInput");
      if(emailInput){
        emailInput.focus();
      }
      setEmails([]);
      setIsUserInvited(true);
      setIsInviteAPICalled(true);
      if(!isForSaskTail) dispatch(createSessionReset());

      if (inviteButton === "InviteAndJoin") {
        openUrlInBrowser(agentUrl, "_self");
        resetDataForSession();
      }
    } else if (inviteSessionsError) {
      handleErrorMsgToastVisiblity(inviteSessionsError.message);
    }
    dispatch(inviteSessionReset());
  }, [inviteSessionsLoading, inviteSessionsData, inviteSessionsError]);

  let emailTemplateStartTime = "";
  let emailTemplateEndTime = "";
  if (sessionDetailData.session?.sessionType === 2) {
    if (sessionDetailData.session?.startAt != null) {
      const utcStartDate = moment.utc(
        sessionDetailData.session.startAt,
        "YYYY-MM-DD hh:mm:ss"
      );
      emailTemplateStartTime = utcStartDate
        .local()
        .format("ddd, MMMM DD, YYYY hh:mm A");
    }
    if (sessionDetailData.session?.endAt != null) {
      const utcEndDate = moment.utc(
        sessionDetailData.session.endAt,
        "YYYY-MM-DD hh:mm:ss"
      );
      emailTemplateEndTime = utcEndDate
        .local()
        .format("ddd, MMMM DD, YYYY hh:mm A");
    }
  }

  const emailTemplateSenderName = sessionDetailData.emailTemplate?.senderName
    .replace(/{{Company Name}}/g, userData?.domainName)
    .replace(/{{Host First Name}}/g, configData?.FNAME)
    .replace(/{{Host Last Name}}/g, configData?.LNAME);

  const emailTemplateSubject = sessionDetailData.emailTemplate?.emailSubject
    .replace(/{{Company Name}}/g, userData?.domainName)
    .replace(/{{Host First Name}}/g, configData?.FNAME)
    .replace(/{{Host Last Name}}/g, configData?.LNAME)
    .replace(/{{Reference}}/g, sessionDetailData?.session?.reference);

  const emailBody =
    '<div id="preview-email-body-iframe-div" style="word-break: break-word;">' +
    sessionDetailData?.emailTemplate?.emailBody +
    "</div>";
  const emailTemplateBody = emailBody
    .replace(/{{Company Name}}/g, userData?.domainName)
    .replace(
      /{{Reference}}/g,
      sessionDetailData ? sessionDetailData?.session?.reference : ""
    )
    .replace(/{{Host First Name}}/g, configData?.FNAME)
    .replace(/{{Host Last Name}}/g, configData?.LNAME)
    .replace(/{{Session Link}}/g, sendUrl)
    .replace(/{{QR Code}}/g, "<img src=" + QRcodeImage + ' alt="QR Code" />')
    .replace(/{{Start Time \(Scheduled only\)}}/g, emailTemplateStartTime)
    .replace(/{{End Time \(Scheduled only\)}}/g, emailTemplateEndTime);

  const openEmailTemplatePreviewModal = () => {
    setShowEmailPreviewModal(true);
    setTimeout(() => {
      let element = document
        .querySelector("#preview-email-body-iframe")
        .contentWindow.document.querySelector("#preview-email-body-iframe-div");
      let height = element.offsetHeight;
      setEmailTemplateheight(height + 20);
    }, 400);
  };

  const previewModalBody = (
    <div className="preview-email-box custom-scroll-div">
      <div className="preview-email-head">
        <p>
          <strong>{localisedValues["sender_email"]}:</strong>{" "}
          {sessionDetailData.emailTemplate?.senderEmail}
        </p>
        <p>
          <strong>{localisedValues["sender_name"]}:</strong>{" "}
          {emailTemplateSenderName}
        </p>
        <p>
          <strong>{localisedValues["subject"]}:</strong> {emailTemplateSubject}
        </p>
        <p>
          <strong>{localisedValues["body"]}</strong>
        </p>
      </div>
      <div className="preview-email-body">
        <iframe
          title="email-preview-body"
          scrolling="no"
          id="preview-email-body-iframe"
          height={emailTemplateheight}
          srcDoc={emailTemplateBody}
        ></iframe>
      </div>
    </div>
  );

  const previewModalFooter = (
    <div className="button-inline d-flex">
      <div className="button">
        <button
          type="button"
          className="btn btn-primary font-weight-500"
          data-bs-dismiss="modal"
          onClick={() => setShowEmailPreviewModal(false)}
        >
          {localisedValues["close"]}
        </button>
      </div>
    </div>
  );

  const inviteViaEmailHandler = (buttonType) => {
    if (emails.length > 0) {
      const request = {
        invitationType: 3,
        sessionId: sessionDetailData.session?.sessionId,
        isoCode: "",
        emailAddress: emails.join(),
        url: sendUrl,
      };
      dispatch(inviteSession(request));
      setInviteButton(buttonType);
    }
  };

  const onEmailChangeHandler = (value) => {
    const updatedValue = value?.replace(",", "")?.trim();
    const isValidEmailId = updatedValue ? emaiRregex.test(updatedValue) : emails.length > 0;

    setIsUserInvited(false);
    setIsButtonsDisabled(!isValidEmailId);
    setIsValidEmail(true);

    if(value === "" && emails.length !== 0){
      setIsButtonsDisabled(false)
    }
  };

  return (
    <>
      <div
        className={clsx("tab-pane", isDefaultInvite ? "active show" : "")}
        id={id}
        data-testid={id}
        role="tabpanel"
        aria-labelledby="invite-email-tab"
      >
        <div className="guest-invite-box">
          <div className="guest-invite-body guest-invite-email-body">
            <div className="guest-invite-email-body-in">
              <div className="title">
                <h2 className="title-mob">{localisedValues["email"]}</h2>
                <h3 className="title-desktop">
                  {localisedValues["enter_email_send_invite"]}
                </h3>
              </div>
              <div className="form-group">
                <ReactMultiEmail
                  id="emailInput"
                  placeholder={localisedValues["new_email_address"]}
                  emails={emails}
                  autoFocus={true}
                  className="multiple-email custom-scroll-div-thin"
                  onChange={(_emails) => {
                    console.log(_emails);
                    setEmails(_emails);
                  }}
                  onChangeInput={onEmailChangeHandler}
                  validateEmail={(email) => {
                    setIsValidEmail(emaiRregex.test(email));
                    setIsButtonsDisabled(!emaiRregex.test(email));
                    return emaiRregex.test(email);
                  }}
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => setFocused(false)}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index} className="email-id">
                        <div data-tag-item className="email-id-text">
                          {email}
                        </div>
                        <button
                          data-tag-handle
                          className="btn remove-email-btn"
                          onClick={() => removeEmail(index)}
                        >
                          <span className="btn-icon icon-close"></span>
                        </button>
                      </div>
                    );
                  }}
                />
                {!isValidEmail && !focused && (
                  <div className="validation-error">
                    {localisedValues["enter_valid_email"]}
                  </div>
                )}
              </div>

              <div className="email-preview-btn text-center">
                <button
                  className="btn btn-link text-dec-none font-weight-600"
                  type="button"
                  data-bs-target="#preview-email-modal"
                  onClick={openEmailTemplatePreviewModal}
                >
                  {localisedValues["preview_email"]}
                </button>
              </div>
              {isUserInvited && (
                <div className="send-success">
                  <span className="send-icon icon-checked-fill"></span>
                  <span className="send-text">{emailSentSuccessMessage}</span>
                </div>
              )}
            </div>
          </div>
          <div className="guest-invite-footer">
            <div className="button-inline text-center d-flex">
              <div className="button join-call-btn">
                {isInviteAPICalled && !isUploadOnlySessionValue && isSessionOwner && (
                  <div className="join-call-btn-in">
                    <button
                      type="button"
                      className="btn btn-link font-weight-500"
                      onClick={() => {openUrlInBrowser(agentUrl, "_self");resetDataForSession();}}
                    >
                      {localisedValues["join_call"]}
                    </button>
                  </div>
                )}
              </div>
              {!isUploadOnlySessionValue ? (
                <>
                  <div className="button">
                    <ButtonWithLoader
                      data-testid="InviteButton"
                      className="btn btn-light-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                      name={localisedValues["invite"]}
                      disable={isButtonsDisabled}
                      showLoader={
                        inviteButton === "Invite" && inviteSessionsLoading
                      }
                      onClick={() => inviteViaEmailHandler("Invite")}
                    />
                  </div>
                  {isSessionOwner && <div className="button">
                    <ButtonWithLoader
                      className="btn btn-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                      name={localisedValues["invite_and_join"]}
                      disable={isButtonsDisabled}
                      showLoader={
                        inviteButton === "InviteAndJoin" &&
                        inviteSessionsLoading
                      }
                      onClick={() => inviteViaEmailHandler("InviteAndJoin")}
                    />
                  </div>}
                </>
              ) : (
                <div className="button">
                  <ButtonWithLoader
                    className="btn btn-primary btn-with-icon font-weight-500 hover-boxshadow loader-sm-space"
                    name={localisedValues["send_link"]}
                    disable={isButtonsDisabled}
                    showLoader={
                      inviteButton === "Invite" && inviteSessionsLoading
                    }
                    onClick={() => inviteViaEmailHandler("Invite")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Preview email modal --> */}
      {showEmailPreviewModal && (
        <PopupModal
          closeModal={() => setShowEmailPreviewModal(false)}
          dialogClass="modal-dialog-centered"
          modalClass="preview-email-modal alert-cms-modal"
          headerContent={
            <h4 className="modal-title">{localisedValues["preview_email"]}</h4>
          }
          bodyContent={previewModalBody}
          footerContent={previewModalFooter}
          backdrop="static"
          disablekeyboard={true}
        />
      )}
    </>
  );
};

SendEmail.propTypes = {
  id: PropTypes.string,
  isDefaultInvite: PropTypes.bool,
  sessionDetailData: PropTypes.object,
  sendUrl: PropTypes.string,
  agentUrl: PropTypes.string,
  userData: PropTypes.object,
  isUploadOnlySessionValue: PropTypes.bool,
  emailRef: PropTypes.object,
  isInviteAPICalledRef: PropTypes.object,
  configData: PropTypes.object,
};

export default SendEmail;
